<template>
  <b-list-group-item
    class="d-flex align-items-center border-0"
    v-b-modal.modal-add-staff
    :disabled="disabled"
  >
    <b-avatar class="mr-1" variant="primary" v-bind:style="{opacity: disabled === true ? 0.2 : 1}">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-person-plus-fill"
        viewBox="0 0 16 16"
      >
        <path
          d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
        />
        <path
          fill-rule="evenodd"
          d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"
        />
      </svg>
    </b-avatar>
    <div class="mr-auto font-weight-bold text-nowrap">
      加入成員
    </div>

    <b-modal
      id="modal-add-staff"
      title="選擇員工"
      ok-title="確定"
      cancel-title="取消"
      :ok-disabled="!selectedStaff"
      @ok="submitAddMember()"
      @cancel="selectedStaff=null"
      centered
    >
      <vue-typeahead-bootstrap
        :data="staffSuggestions"
        v-model="staffKeyword"
        size="md"
        placeholder="請輸入姓名或員工編號"
        :autoClose="false"
        :serializer="(s) => s.name"
        :disabledValues="['搜尋中...']"
        :showAllResults="true"
        :min-matching-chars="1"
        @input="lookupStaff"
        @hit="handleSelected"
      >
        <template slot="suggestion" slot-scope="{ data }">
          <div class="d-flex align-items-center">
            <b-avatar
              class="mr-2"
              :src="data.avatar_url"
            ></b-avatar>
            <div
              class="
                mr-auto
                font-weight-bold
                text-nowrap text-truncate
              "
            >
              {{ data.name }}<br>
              <span class="staff-list-info">
                {{ displayStaffInfo(data) }}
              </span>
            </div>
          </div>
        </template>
      </vue-typeahead-bootstrap>

      <div
        v-if="selectedStaff"
        class="d-flex align-items-center py-3"
      >
        <b-avatar
          class="mr-2"
          :src="selectedStaff.avatar_url"
        ></b-avatar>
        <div
          class="
            mr-auto
            font-weight-bold
            text-nowrap text-truncate
            flex-fill
          "
        >
          {{ selectedStaff.name }}<br>
          <span class="staff-list-info">
            {{ displayStaffInfo(selectedStaff) }}
          </span>
        </div>
        <b-button
          size="xs"
          pill
          variant="danger"
          class="p-1 mr-2"
          @click="selectedStaff = null"
          ><i class="fa fa-times m-0"></i
        ></b-button>
      </div>
    </b-modal>
  </b-list-group-item>
</template>

<script>
import VueTypeaheadBootstrap from "@/components/VueTypeaheadBootstrap/VueTypeaheadBootstrap.vue";
import chatroomApi from "@/apis/chatrooms";

export default {
  components: {
    VueTypeaheadBootstrap,
  },
  props: {
    chatroom: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedStaff: null,
      staffKeyword: null,
      staffSuggestions: [],
      requestLastTime: null,
    }
  },
  methods: {
    displayStaffInfo(staff) {
      if (staff.name === '搜尋中...') return null;
      return ((staff.employee_code) ? staff.employee_code + " " : "") + (staff.email ?? "");
    },
    async lookupStaff() {
      if (this.staffKeyword == null || this.staffKeyword == "") return;
      this.staffSuggestions = [{name: "搜尋中...", avatar_url: null}];

      const delay = 300;
      this.requestLastTime = (new Date()).valueOf();
      await this.sleep(delay);
      let nowTime = (new Date()).valueOf();
      let gap = nowTime - this.requestLastTime;
      if (gap  < delay) {
        return;
      } else {
        if (!this.staffKeyword) return;
        let response = await chatroomApi.searchStaffs({ params: { keyword: this.staffKeyword }});
        this.staffSuggestions = response.data.data;
      }
    },
    async sleep(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
    },
    async submitAddMember() {
      try {
        await chatroomApi.addMember({
          chatroom: this.chatroom,
          params: {
            staff_id: this.selectedStaff.id,
          }
        });
        this.$swal("新增聊天室成員成功", "", "success");
        this.$emit("added");
        this.selectedStaff = null;
        this.staffKeyword = null;
      } catch (error) {
        console.error(error);

        let message = null;

        if (error.response.data.message) {
          message = error.response.data.message;
        }
        this.$swal("新增失敗", message, "warning");
      }
    },
    handleSelected(e) {
      this.selectedStaff = e;
      this.staffKeyword = null;
    },
  }
}
</script>

<style>
.staff-list-info {
  font-size: 0.75rem;
  color: gray;
  font-weight: 400;
}
</style>
