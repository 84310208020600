<template>
  <b-dropdown class="filter w-100" ref="Filter">
    <template #button-content>
      <div class="flex-fill">{{ title }}</div>
    </template>

    <div class="search mb-2 px-2" v-if="showKeywordFilter">
      <i class="fa fa-search" aria-hidden="true"></i>
      <b-form-input
        type="search"
        v-model.trim="keyword"
        placeholder="搜尋"
      ></b-form-input>
    </div>

    <div
      v-for="i in filters.filter((x) => {
        return keyword == null || keyword == ''
          ? true
          : x.text.indexOf(keyword) >= 0;
      })"
      :key="i.value"
      @click="handleSelect(i)"
      class="item py-1 px-3"
    >
      <span class="d-flex justify-content-between align-items-center">
        <i
          :class="`fa ${
            selectedOptions.includes(i)
              ? 'fa-check-square-o'
              : 'fa-square-o'
          } mr-3`"
          aria-hidden="true"
        ></i>
        <span :class="`mb-0 px-3 py-2 badge badge-${i.variant} flex-fill`">{{
          i.text
        }}</span>
      </span>
    </div>
  </b-dropdown>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "篩選",
    },
    showKeywordFilter: {
      type: Boolean,
      default: false,
    },
    selectedOptions: {
      type: Array,
    },
    filters: {
      type: Array,
    },
    keyword: {
      type: String,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    selected: {
      get() {
        return this.value;
      },
      set() {
        // this.$emit("input", v);
      },
    },
  },
  methods: {
    handleSelect(v) {
      // console.log(v)
      this.$emit("click", v);
    },
  },
};
</script>

<style scoped>
</style>