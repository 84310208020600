import https from "./https";
import store from "@/store";

const module = {
  list() {
    const organization = store.state.general.organization;

    return https.get("/admin/organizations/" + organization.id + "/modules");
  },
  updateOa(module_id) {
    return https.put("/admin/modules/" + module_id + "/line_oas");
  },
  getModules(orgId, params) {
    if (!orgId) {
      return Promise.reject("orgId not found.");
    }

    const searchParams = new URLSearchParams(params);

    return https.get(
      `/admin/system-settings/organizations/${orgId}/modules?${searchParams}`
    );
  },
  getLiffModuleList(orgId) {
    if (!orgId) {
      return Promise.reject("orgId not found.");
    }

    return https.get(
      `/admin/system-settings/organizations/${orgId}/liff-page-module`
    );
  },
  getModule(orgId, moduleId) {
    if (!orgId) {
      return Promise.reject("orgId not found.");
    }

    if (!moduleId) {
      return Promise.reject("id not found.");
    }

    return https.get(
      `/admin/system-settings/organizations/${orgId}/modules/${moduleId}`
    );
  },
  createModule(orgId, module) {
    if (!orgId) {
      return Promise.reject("orgId not found.");
    }

    return https.post(
      `/admin/system-settings/organizations/${orgId}/modules`,
      module
    );
  },
  activateModule(orgId, module) {
    if (!orgId) {
      return Promise.reject("orgId not found.");
    }

    return https.post(
      `/admin/system-settings/organizations/${orgId}/module-activate`,
      module
    );
  },
  updateModule(orgId, moduleId, module) {
    if (!orgId) {
      return Promise.reject("orgId not found.");
    }

    if (!moduleId) {
      return Promise.reject("id not found.");
    }

    return https.put(
      `/admin/system-settings/organizations/${orgId}/modules/${moduleId}`,
      module
    );
  },
  deleteModule(orgId, moduleId) {
    if (!orgId) {
      return Promise.reject("orgId not found.");
    }

    if (!moduleId) {
      return Promise.reject("id not found.");
    }

    return https.delete(
      `/admin/system-settings/organizations/${orgId}/modules/${moduleId}`
    );
  },
  getModuleConfig(params) {
    const organization = store.state.general.organization;
    return https.get(`admin/organizations/${organization.id}/module-config`, {
      params,
    });
  },
  getModuleConfigMap(orgId, moduleId) {
    if (!orgId) {
      return Promise.reject("orgId not found.");
    }

    if (!moduleId) {
      return Promise.reject("id not found.");
    }

    return https.get(
      `/admin/system-settings/organizations/${orgId}/modules/${moduleId}/config`
    )
  },
  updateModuleConfigMap(orgId, moduleId, module) {
    if (!orgId) {
      return Promise.reject("orgId not found.");
    }

    if (!moduleId) {
      return Promise.reject("id not found.");
    }

    return https.put(
      `/admin/system-settings/organizations/${orgId}/modules/${moduleId}/config`,
      module
    );
  },
  getModuleConfigMapByType(orgId, id, table, type) {
    if (!orgId) {
      return Promise.reject("orgId not found.");
    }

    if (!id) {
      return Promise.reject("id not found.");
    }

    return https.get(
      `/admin/system-settings/organizations/${orgId}/modules/${id}/config-map?table=${table}&type=${type}`
    )
  },
  updateModuleConfigMapByType(orgId, id, module, table, type) {
    if (!orgId) {
      return Promise.reject("orgId not found.");
    }

    if (!id) {
      return Promise.reject("id not found.");
    }

    return https.put(
      `/admin/system-settings/organizations/${orgId}/modules/${id}/config-map?table=${table}&type=${type}`,
      module
    );
  },
};

export default module;
