import https from "./https";
import store from "@/store";

const chatrooms = {
  getChatrooms(params) {
    const organization = store.state.general.organization;
    return https.get(`admin/organizations/${organization.id}/chatrooms`, {
      params,
    });
  },

  getSingleChatrooms(chatroomId) {
    const organization = store.state.general.organization;
    return https.get(`admin/organizations/${organization.id}/chatrooms/${chatroomId}`);
  },

  getMessages({ chatroom, cursor, count }) {
    return https.get(
      `admin/chatrooms/${chatroom.id}/messages?cursor=${cursor}&count=${count}`
    );
  },

  sendMessage({ chatroom, message, state }) {
    return https.post(`admin/chatrooms/${chatroom.id}/messages`, {
      state,
      message,
    });
  },

  markAsRead({ chatroom }) {
    return https.put(`admin/chatrooms/${chatroom.id}/read`);
  },

  getCustomers(chatroom) {
    const organization = store.state.general.organization;
    return https.get(
      `admin/organizations/${organization.id}/chatrooms/${chatroom.id}/customers`
    );
  },

  getStaffs(chatroom) {
    const organization = store.state.general.organization;
    return https.get(
      `admin/organizations/${organization.id}/chatrooms/${chatroom.id}/staffs`
    );
  },

  update(chatroomId, param) {
    const organization = store.state.general.organization;
    return https.put(
      `admin/organizations/${organization.id}/chatrooms/${chatroomId}`,
      param
    );
  },

  pin(chatroomId) {
    const organization = store.state.general.organization;
    return https.post(
      `admin/organizations/${organization.id}/chatrooms/${chatroomId}/pin`
    );
  },

  unpin(chatroomId) {
    const organization = store.state.general.organization;
    return https.post(`admin/organizations/${organization.id}/chatrooms/${chatroomId}/unpin`);
  },

  async upload(chatroomId, files) {
    const { data } = await https.post(
      `admin/chatrooms/${chatroomId}/upload`,
      { files }
    );

    return data;
  },

  markAsUploaded(chatroomId, { state, files }) {
    return https.put(`admin/chatrooms/${chatroomId}/uploaded`, {
      state,
      files,
    });
  },

  getFileList({ chatroom, params }) {
    const organization = store.state.general.organization;
    return https.get(`admin/organizations/${organization.id}/chatrooms/${chatroom.id}/files`, {params});
  },

  searchStaffs({ params }) {
    const organization = store.state.general.organization;
    return https.get(`admin/organizations/${organization.id}/chatrooms/search-staffs`, { params });
  },

  addMember({ chatroom, params }) {
    const organization = store.state.general.organization;
    return https.post(`admin/organizations/${organization.id}/chatrooms/${chatroom.id}/add-member`, params);
  },

  removeMember({ chatroom, params }) {
    const organization = store.state.general.organization;
    return https.post(`admin/organizations/${organization.id} /chatrooms/${chatroom.id}/remove-member`, params);
  },

  recallMessage({ chatroom, messages}) {
    return https.post(`admin/chatrooms/${chatroom.id}/messages/recall`, {
      recall_messages: messages,
    });
  },

  async getStickerPackages() {
    const { data: { data } } = await https.get('chatroom/sticker/packages');

    return data
  },

  async getStickers({ packageId }) {
    const { data: { data } } = await https.get(`chatroom/sticker/packages/${packageId}`);

    return data
  },

  sendSticker({ chatroom, stickerId, state }) {
    return https.post(`admin/chatrooms/${chatroom.id}/send-sticker`, {
      state,
      stickerId,
    });
  },
};

export default chatrooms;
