<template>
  <b-list-group-item
    class="d-flex align-items-center border-0 position-relative chatroom-list-item"
    :variant="variant"
  >
    <span class="pinned" v-if="isPinned">
      <i class="fa fa-thumb-tack text-warning" aria-hidden="true"></i>
    </span>
    <b-avatar class="mr-1" :src="getStaffAvatar(chatroom)"></b-avatar>
    <b-avatar class="mr-3" :src="getCustomerAvatar(chatroom)"></b-avatar>
    <div class="font-weight-bold title-and-message">
      <p class="chatroom-title" :title="title">
        <b-badge class="text-primary" variant="primary" v-if="category">{{ category }}</b-badge>
        {{ title }}
      </p>
      <p :class="hasUnreadMessage ? 'text-bold' : 'text-muted small'" :title="lastMessage">
        {{ lastMessage }}
      </p>
    </div>
    <div class="info">
      <time class="text-muted small">
        <template v-if="chatroom.message.updated_at">
          {{ formatDate(chatroom.message.updated_at) }}
        </template>
        <template v-else>&nbsp;</template>
      </time>
      <div class="badges">
        <b-badge
          pill
          v-if="chatroom.status"
          :variant="chatroomStatusVariantMapping[chatroom.status]"
        >
          {{ chatroomStatusTextMapping[chatroom.status] }}
        </b-badge>
        <b-badge
          v-if="chatroom.is_new_created"
          pill
          variant="outline-info"
        >
          New
        </b-badge>
        <b-badge v-if="hasUnreadMessage" pill variant="primary">
          {{ unreadCount }}
        </b-badge>
      </div>
    </div>
  </b-list-group-item>
</template>

<script>
import "bootstrap-vue/dist/bootstrap-vue.css";
import format from "date-fns/format";
import {
  getChatroomAvatar,
  getChatroomTitle,
  getChatroomLastMessage,
  formatDate,
  getStaffAvatar,
  getCustomerAvatar,
} from "@/utils/chatroomHelper.js";

import {
  chatroomStatusTextMapping,
  chatroomStatusVariantMapping,
} from "@/pages/Dashboard/Chatrooms/Chatroom.utils.js";

export default {
  props: {
    variant: {
      type: String,
      default: "default",
    },
    chatroom: {
      type: Object,
    },
    staffID: {
      type: String
    },
    categories: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      chatroomStatusTextMapping,
      chatroomStatusVariantMapping,
    };
  },
  computed: {
    isPinned: function () {
      if (
        this.chatroom.extensions &&
        this.chatroom.extensions[0] &&
        this.chatroom.extensions[0].pinned_at
      ) {
        return true;
      }

      return false;
    },
    unreadCount() {
      let sender = this.chatroom.senders.find((s) => s.id === this.staffID)
      let count = 0
      if (sender) {
        if (!("unread_count" in sender)) {
          sender.unread_count = 0;
        }
        count = sender.unread_count;
      }

      if (count > 10) {
        count = '9+'
      }

      return count
    },
    hasUnreadMessage() {
      return !!this.unreadCount
    },
    title() {
      return this.getChatroomTitle(this.chatroom, true)
    },
    lastMessage() {
      return this.getChatroomLastMessage(this.chatroom)
    },
    categoriesMap() {
      return this.categories.reduce((carry, { key, display_name }) => ({
        ...carry,
        [key]: display_name,
      }), {})
    },
    category() {
      return this.categoriesMap[this.chatroom.category]
    },
  },
  methods: {
    format,
    getChatroomAvatar,
    getChatroomTitle,
    getChatroomLastMessage,
    formatDate,
    getStaffAvatar,
    getCustomerAvatar,
  },
};
</script>

<style lang="scss" scoped>
.pinned {
  position: absolute;
  top: 5px;
  left: 10px;
  transform: rotate(45deg);
}

.text-bold {
  font-weight: 700;
}

.chatroom-list-item {
  width: 100%;

  p {
    margin: 0;
  }

  > * {
    flex-shrink: 0;

    &.title-and-message {
      flex: 1 1 0;
      width: 0;
      margin-right: 1rem;

      > p {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .chatroom-title {
        .badge {
          background: transparent;
          margin-right: 0.25rem;
          transform: translateY(-1px);
        }
      }
    }

    &.info {
      text-align: right;

      .badges {
        * + * {
          margin-left: 0.25rem;
        }
      }
    }
  }
}
</style>
