export const CHATROOM_STATUS = {
  PENDING: "pending",
  DOING: "doing",
  DONE: "done",
  // ARCHIVED: "archived",
};

export const chatroomStatusTextMapping = {
  [CHATROOM_STATUS.PENDING]: "待處理",
  [CHATROOM_STATUS.DOING]: "處理中",
  [CHATROOM_STATUS.DONE]: "處理完畢",
  // [CHATROOM_STATUS.ARCHIVED]: "歸檔",
};

export const chatroomStatusVariantMapping = {
  [CHATROOM_STATUS.PENDING]: "danger",
  [CHATROOM_STATUS.DOING]: "warning",
  [CHATROOM_STATUS.DONE]: "primary",
  // [CHATROOM_STATUS.ARCHIVED]: "success",
};
