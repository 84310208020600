import format from "date-fns/format";

export function getChatroomAvatar(chatroom) {
  if (!chatroom || chatroom.senders?.length === 0) {
    return null;
  }
  return chatroom.senders[0].avatar_url;
}

export function getStaffAvatar(chatroom) {
  if (!chatroom || chatroom.staffs?.length === 0) {
    return null;
  }

  return chatroom.staffs[0]?.avatar_url ?? null;
}

export function getCustomerAvatar(chatroom) {
  if (!chatroom || chatroom.customers?.length === 0) {
    return null;
  }

  return chatroom.customers[0]?.avatar_url ?? null;
}

export function getChatroomTitle(chatroom, forDashboard = false) {
  if (! chatroom) {
    return "無標題";
  }

  if (chatroom.source_type === 'customer_staff' && forDashboard) {
    return chatroom.senders[0]?.nickname ?? chatroom.senders[0].name ?? "不明的帳號";
  }

  if (chatroom.title) {
    return chatroom.title;
  }

  if (chatroom.senders.length === 1) {
    return chatroom.senders[0]?.nickname ?? chatroom.senders[0].name;
  }

  if (chatroom.senders.length > 1 && chatroom.senders[0]?.name) {
    return chatroom.senders[0]?.nickname ?? chatroom.senders[0].name + "等" + (chatroom.senders.length - 1) + "人";
  }

  return "無標題";
}

export function getChatroomLastMessage(chatroom) {
  if (chatroom.message) {
    return formatMessage(chatroom.message)
  }

  if (chatroom.messages?.length > 0) {
    return formatMessage(chatroom.messages[0])
  }

  return null;
}

function formatMessage(message) {
  let result = message.content || ''

  if (message.type === 'text') result = message.text
  if (message.type === 'file') result = '檔案'
  if (message.type === 'image') result = '圖片'
  if (message.type === 'sticker') result = '貼圖'
  if (result.length > 16) result = result.slice(0, 16) + '...'

  return result || null
}

export function formatDate(dateString, todayFormat = "HH:mm") {
  let now = new Date();

  let date = new Date(dateString);

  let sameYear = now.getFullYear() == date.getFullYear();
  let sameDate =
    now.getMonth() == date.getMonth() && now.getDate() == date.getDate();

  function getFormat() {
    if (sameYear && sameDate) {
      return todayFormat;
    }

    if (sameYear) {
      return "MM/dd";
    }

    return "yyyy/MM/dd";
  }

  return format(date, getFormat());
}

export function formatHourMinutes(dateString) {
  if (dateString === null) return null;

  try {
    let date = new Date(dateString);
    return format(date, "HH:mm");
  } catch (error) {
    console.error(error);
    return null;
  }
}
