<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item
              :to="{
                name: 'ChatroomIndex',
              }"
              >聊天室管理</b-breadcrumb-item
            >
            <b-breadcrumb-item active>聊天室總覽</b-breadcrumb-item>
          </b-breadcrumb>
          <div
            class="ml-auto d-flex align-items-center"
            v-if="
              newestNotification &&
              $permissions.has($permissions.consts.MY_NOTIFICATION_LATEST)
            "
          >
            <router-link
              :to="{
                name: 'BranchNotificationDetail',
                params: { notification_id: newestNotification.id },
              }"
            >
              <i
                :class="{
                  'fa fa-envelope-o text-danger': !newestNotification.is_read,
                  'fa fa-envelope-open-o text-black':
                    newestNotification.is_read,
                }"
              />
              <a
                class="ml-2 text-black"
                :class="{
                  'text-danger': !newestNotification.is_read,
                }"
              >
                {{ newestNotification.title }} :
                {{
                  format(new Date(newestNotification.publish_at), "MM/dd HH:mm")
                }}
              </a>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-nickname"
      title="自訂暱稱"
      ok-title="儲存"
      cancel-title="取消"
      centered
      @ok="handleUpdateCustomerProfile"
      @hidden="handleCloseUpdateNickname"
    >
      <b-input
        type="text"
        class="form-control"
        maxlength="20"
        placeholder="自訂暱稱不會向對方公開"
        v-model="inputNickname"
      />
    </b-modal>

    <b-modal
      id="modal-name"
      title="變更姓名"
      ok-title="儲存"
      cancel-title="取消"
      centered
      @ok="handleUpdateCustomerProfile"
    >
      <b-input
        type="text"
        class="form-control"
        maxlength="20"
        placeholder="變更姓名"
        v-model="customerProfile.name"
      />
    </b-modal>

    <b-modal
      id="modal-personal-id"
      title="變更身份證字號"
      ok-title="儲存"
      cancel-title="取消"
      centered
      @ok="handleUpdateCustomerProfile"
    >
      <b-input
        type="text"
        class="form-control"
        maxlength="20"
        placeholder="變更身份證字號"
        v-model="customerProfile.personal_id"
      />
    </b-modal>

    <b-modal
      id="modal-tags"
      title="編輯標籤"
      ok-title="儲存"
      cancel-title="取消"
      centered
      @ok="handleSaveTags"
    >
      <div class="border rounded p-1">
        <b-badge
          v-for="(tag, index) in tags"
          :key="tag.id"
          variant="dark"
          class="m-1"
        >
          {{ tag.name }}
          <button class="btn btn-xs p-0" @click="handleClickRemoveTag(index)">
            <i class="fa fa-remove text-white" />
          </button>
        </b-badge>
        <b-input
          type="text"
          class="border-0"
          maxlength="40"
          placeholder="輸入標籤"
          v-model="inputTag"
          @keyup.enter="handleEnterInputTag"
          @keyup="handleKeyUpInputTag"
          @blur="handleEnterInputTag"
        />
      </div>

      <template v-if="false">
        <div class="h6 mt-3">現有標籤</div>
        <b-button
          v-show="false"
          v-for="i in tags"
          :key="i"
          variant="secondary"
          class="m-1 text-dark"
          size="xs"
        >
          {{ i }}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-note"
      title="自訂筆記"
      ok-title="儲存"
      cancel-title="取消"
      centered
      @ok="handleSaveNote"
    >
      <b-textarea
        class="form-control"
        maxlength="300"
        placeholder="你可於此新增用戶的相關資訊，且記事本中的資訊不會向對方公開"
        rows="10"
        v-model="customerProfile.note"
      />
    </b-modal>

    <b-modal id="modal-showImg" ok-title="關閉" centered ok-only>
      <img style="width: 480px" :src="currentImg" />
    </b-modal>

    <b-card no-body class="chatroom d-flex flex-row">
      <div
        ref="chatlist"
        class="chat-list position-relative d-flex flex-column border-right"
      >
        <div class="header px-3 pt-3 pb-2 border-bottom">
          <div class="search mb-2">
            <b-form-select
              v-if="canShowBranchSelector"
              :value="selectedBranch"
              :options="branchOptions"
              :disabled="changingBranch"
              @change="handleChangeSelectedBranch"
            >
              <template #first>
                <b-form-select-option :value="null" disabled
                  >-- 選擇分店 --</b-form-select-option
                >
              </template>
            </b-form-select>
          </div>

          <div class="search mb-2">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path
                d="M500.3 443.7l-119.7-119.7c27.22-40.41 40.65-90.9 33.46-144.7C401.8 87.79 326.8 13.32 235.2 1.723C99.01-15.51-15.51 99.01 1.724 235.2c11.6 91.64 86.08 166.7 177.6 178.9c53.8 7.189 104.3-6.236 144.7-33.46l119.7 119.7c15.62 15.62 40.95 15.62 56.57 0C515.9 484.7 515.9 459.3 500.3 443.7zM79.1 208c0-70.58 57.42-128 128-128s128 57.42 128 128c0 70.58-57.42 128-128 128S79.1 278.6 79.1 208z"
              />
            </svg>
            <b-form-input
              type="search"
              v-model.trim="keyword"
              placeholder="姓名搜尋"
              @input="keywordSearchDebounced"
            ></b-form-input>
          </div>

          <ChatroomMultiselect
            v-if="allowFilterCategory"
            title="訊息類型"
            :filters="filterCategoryOptions"
            :showKeywordFilter=false
            :selectedOptions="selectedCategoryFilter"
            @click="handleSelectedCategory"
          />

          <ChatroomMultiselect
            v-if="allowFilterStatus"
            title="處理狀態"
            :filters="statusOptions"
            :showKeywordFilter=false
            :selectedOptions="selectedStatus"
            @click="handleSelectedStatus"
          />

          <div v-if="false" class="filter">
            <b-dropdown id="dropdown-1" text="所有收件匣" variant="light">
              <b-dropdown-item v-for="i in filters" :key="i.id">
                <i :class="[i.icon]" aria-hidden="true"></i> {{ i.id }}
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>

        <b-list-group class="list list-group-flush" @scroll="handleChatroomListScroll" ref="chatroomList">
          <b-list-group-item v-if="!initialized || changingBranch || changingFilter || keywordFilter" class="empty">
            <div class="text-center">
              <b-spinner variant="secondary" small />
            </div>
          </b-list-group-item>
          <template v-else-if="orderedChatrooms.length > 0">
            <div
              v-for="chatroom in orderedChatrooms"
              :key="chatroom.id"
              @click="selectChatroom(chatroom)"
            >
              <ChatroomListItem
                role="button"
                :chatroom="chatroom"
                :variant="chatroomId == chatroom.id ? 'secondary' : 'default'"
                :staffID="staffId"
                :categories="categoryFilterOptions"
              />
            </div>
            <div class="text-center mt-3 mb-3 loading-spinner" v-if="loading">
              <b-spinner variant="secondary" small />
            </div>
          </template>
          <b-list-group-item v-else class="empty">
            <!-- <img src="./images/empty.svg" /> -->
            <div class="mt-3 text-dark small">目前沒有名單</div>
          </b-list-group-item>
        </b-list-group>
      </div>

      <div class="resizer" @mousedown="resizeStart" @mouseup="resizeEnd">
        <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
      </div>

      <div class="chatbox flex-fill d-flex flex-column border-right">
        <div class="header">
          <div
            class="
              d-flex
              justify-content-between
              px-3
              py-2
              pe-0
              border-bottom
              flex-wrap
            "
          >
            <div v-if="selectedChatroom" class="font-weight-bold">
              <b-avatar class="mr-1">
                <img
                  :src="getStaffAvatar(selectedChatroom)"
                  class="img-fluid"
                />
              </b-avatar>
              <b-avatar class="mr-3">
                <img
                  :src="getCustomerAvatar(selectedChatroom)"
                  class="img-fluid"
                />
              </b-avatar>
              {{ getChatroomTitle(selectedChatroom, true) }}
            </div>

            <div class="d-flex">
              <b-button
                v-if="false"
                class="m-1"
                @click="
                  () => {
                    let msg = Object.assign(messages[1]);
                    messages = [msg].concat(messages);
                    unread = true;
                  }
                "
              >
                Test Message</b-button
              >
              <div v-if="selectedChatroom">
                <b-button
                  @click="handleClickPin"
                  v-if="canShowPinButton"
                  class="m-1"
                  :variant="`${
                      isSelectedChatroomPinned ? 'warning' : 'outline-warning'
                    }`"
                >
                  {{ isSelectedChatroomPinned ? "取消釘選" : "釘選" }}
                  <i
                    class="fa fa-thumb-tack"
                    style="transform: rotate(45deg)"
                    aria-hidden="true"
                  ></i>
                </b-button>
                <template v-if="canShowStatusButtons">
                  <b-dropdown class="filter col-6" ref="Filter" :variant="selectedChatroomStatusVariant">
                    <template #button-content>
                      <div class="flex-fill">{{ selectedChatroomStatusName }}</div>
                    </template>

                    <div
                      v-for="status in statusOptions"
                      :key="status.value"
                      @click="handleClickChangeStatus(status.value)"
                      class="item py-1 px-3"
                    >
                      <span class="d-flex justify-content-between align-items-center">
                        <i
                          :class="`${
                             (selectedChatroomStatus === status.value) || (!selectedChatroomStatus && status.value === 'not-setting')
                              ? 'fa fa-check'
                              : ''
                          } mr-3`"
                          aria-hidden="true"
                        ></i>
                        <span :class="`mb-0 px-3 py-2 badge badge-${status.variant} flex-fill cursor-pointer`">{{
                          status.text
                        }}</span>
                      </span>
                    </div>
                  </b-dropdown>
                </template>
              </div>

              <b-button
                v-if="false"
                variant="outline-dark"
                class="m-1"
                @click="
                  () => {
                    isMessageSearching = !isMessageSearching;
                  }
                "
              >
                <i class="fa fa-search" aria-hidden="true"></i>
                搜尋</b-button
              >

              <b-button v-if="false" variant="light" class="m-1">
                <i class="fa fa-sign-in m-0" aria-hidden="true"></i>
              </b-button>
              <b-button v-if="false" variant="light" class="m-1">
                <i class="fa fa-check m-0 text-success" aria-hidden="true"></i>
              </b-button>
            </div>
          </div>

          <div
            v-if="isMessageSearching"
            class="search-message p-2 border-bottom d-flex"
          >
            <b-form-input
              size="sm"
              type="search"
              class="flex-fill"
              placeholder="輸入搜尋內容"
            />
            <b-button
              class="btn btn-light ml-2"
              @click="
                () => {
                  isMessageSearching = !isMessageSearching;
                }
              "
              ><i class="fa fa-remove"></i
            ></b-button>
          </div>
        </div>

        <div
          ref="messages"
          class="message-container d-flex flex-column-reverse flex-fill px-3"
          @scroll="handleScroll"
        >
          <div
            :class="['message-box', 'my-2', isSender(message) && 'owner']"
            :key="idx"
            v-for="(message, idx) in messages"
          >
            <div
              class="strike small text-muted pb-2"
              v-if="checkStrike(messages, idx)"
            >
              {{ formatDateD(messages[idx].created_at) }}
            </div>
            <div class="message-wrapper">
              <div class="d-flex align-items-start">
                <b-avatar
                  class="mr-2"
                  :src="message.sender.avatar_url"
                ></b-avatar>
                <div>
                  <small class="mb-1">
                    {{ messageSenderName(message) }}
                  </small>
                  <template v-if="message.type === 'image'">
                    <div
                      class="message image"
                      :class="{ multiple: message.meta.images && message.meta.images.length > 1 }"
                    >
                      <div
                        :class="{ expanded: message.meta.images.length & 1 && i === 0 }"
                        :key="i"
                        v-for="(image, i) in message.meta.images"
                      >
                        <img
                          :src="image.previewImageUrl"
                          @click="showImageLightBox(image.originalContentUrl)"
                        />
                      </div>
                    </div>
                    <div v-if="canShowMultiDownload" class="image-download-section">
                      <div>
                        <a class="download-all ml-2" @click.prevent="selectedImageUrls = message.meta.images.map((image) => image.originalContentUrl);downloadMultiImages();" style="display: flex;">
                          <div>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" style="width: 14px; height: 14px; margin-right: 10px;">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                            </svg>
                          </div>
                          <div>
                            立即下載
                          </div>
                        </a>
                      </div>
                      <!-- <div class="mt-1 custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          name="selectedImageUrls"
                          class="custom-control-input"
                          v-model="selectedImageUrls"
                          :value="message.meta.images.map((image) => image.originalContentUrl)"
                          :id="'selected_images_checkbox_' + message.id"
                        >
                        <label class="custom-control-label" :for="'selected_images_checkbox_' + message.id">
                          選取
                        </label>
                      </div>
                      <div
                        v-if="selectedImageUrls.flat().includes(message.meta.images[0].originalContentUrl)"
                        class="mt-1"
                      >
                        <a class="download-all ml-2" @click.prevent="downloadMultiImages()">批次下載</a>
                        <a class="download-all ml-2" @click.prevent="cancelDownloadMultiImages()">取消批次下載</a>
                      </div> -->
                    </div>
                  </template>
                  <div
                    class="message file py-2 px-3"
                    v-else-if="message.type === 'file'"
                  >
                    <a :href="message.meta.fileUrl" target="_blank">
                      <div class="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                          fill="currentColor"
                        >
                          <path
                            d="M374.6 310.6l-160 160C208.4 476.9 200.2 480 192 480s-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 370.8V64c0-17.69 14.33-31.1 31.1-31.1S224 46.31 224 64v306.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0S387.1 298.1 374.6 310.6z"
                          />
                        </svg>
                      </div>
                      <div class="info">
                        <p>{{ message.meta.fileName }}</p>
                        <p>{{ filesizeForHuman(message.meta.fileSize) }}</p>
                      </div>
                    </a>
                  </div>
                  <div
                    class="message py-2 px-3"
                    v-html="manipulateMessage(message.content)"
                    v-else
                  />
                </div>
              </div>
              <div
                class="
                  small
                  text-muted
                  mt-1
                  d-flex
                  align-items-end
                  justify-content-end
                "
              >
                <ul class="list-unstyled read-list">
                  <template v-for="sender in senders">
                    <li
                      :title="sender.name"
                      :key="sender.id"
                      v-if="senderIsRead(sender, message)"
                    >
                      <b-avatar size="1rem" :src="sender.avatar_url"></b-avatar>
                    </li>
                  </template>
                </ul>
                <time>{{ format(new Date(message.created_at), "HH:mm") }}</time>
              </div>
            </div>
          </div>

          <div class="typing-list" v-if="typingSenders.length">
            <div
              class="message-box my-2"
              :key="sender.id"
              v-for="sender in typingSenders"
            >
              <div class="message-wrapper">
                <div class="d-flex align-items-start">
                  <b-avatar class="mr-2" :src="sender.avatar_url" />
                  <div>
                    <small>{{ sender.name }}</small>
                    <div class="message py-2 px-3 mt-1">
                      <Typing />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="text-muted load-more" v-if="nextCursor && !loading">
            <button class="btn btn-text" @click.prevent="loadMore">
              載入更多
            </button>
          </div>

          <div class="text-center mt-3 mb-auto loading-spinner" v-if="loading">
            <b-spinner variant="secondary" small />
          </div>
        </div>
        <div class="p-2 border-top typebox">
          <div
            class="position-absolute unread d-flex justify-content-center px-3"
            v-if="showIndicator"
          >
            <b-button
              v-if="unread"
              variant="dark"
              size="sm"
              pill
              @click="scrollToBottom"
            >
              <div class="last-message-wrapper">
                {{ latestMessage.sender.name }}：{{ latestMessage.content }}
              </div>
            </b-button>

            <b-button
              v-else-if="!bottom"
              variant="dark"
              size="sm"
              class="px-2"
              pill
              @click="scrollToBottom"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                height="16"
                fill="white"
              >
                <!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                <path
                  d="M224 416c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 338.8l169.4-169.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-192 192C240.4 412.9 232.2 416 224 416z"
                />
              </svg>
            </b-button>
          </div>
          <div
            class="d-flex"
            v-if="allowSendMessage || allowSendImage || allowSendFile"
          >
            <b-modal
              id="modal-image-upload"
              title="傳送照片"
              class="modal-image"
              centered
              hide-footer
              ref="imageModal"
            >
              <div v-if="uploading">上傳中...</div>
              <b-form-file
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                class="uploader"
                accept="image/*"
                @input="handleUpload"
                ref="imageUploader"
                multiple
                v-else
              >
                <template slot="placeholder">
                  <div
                    class="
                      dropzone
                      d-flex
                      flex-column
                      align-items-center
                      justify-content-center
                    "
                  >
                    <div>請將檔案拖放至此</div>
                    <div>或</div>
                    <div>選擇檔案</div>
                  </div>
                </template>
                <template slot="file-name" slot-scope="{ names }">
                  <b-badge variant="dark">{{ names[0] }}</b-badge>
                </template>
              </b-form-file>
            </b-modal>

            <b-modal
              id="modal-file-upload"
              title="傳送檔案"
              class="modal-image"
              centered
              hide-footer
              ref="fileModal"
            >
              <div v-if="uploading">上傳中...</div>
              <b-form-file
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                class="uploader"
                @input="handleUpload"
                ref="fileUploader"
                multiple
                v-else
              >
                <template slot="placeholder">
                  <div
                    class="
                      dropzone
                      d-flex
                      flex-column
                      align-items-center
                      justify-content-center
                    "
                  >
                    <div>請將檔案拖放至此</div>
                    <div>或</div>
                    <div>選擇檔案</div>
                  </div>
                </template>
                <template slot="file-name" slot-scope="{ names }">
                  <b-badge variant="dark">{{ names[0] }}</b-badge>
                </template>
              </b-form-file>
            </b-modal>
            <template v-if="canShowChatReply && selectedChatroom">
              <button
                v-if="allowSendImage"
                v-b-modal.modal-image-upload
                class="btn btn-link px-2 text-dark"
                :disabled="!selectedChatroom.is_enabled"
              >
                <i class="fa fa-picture-o" aria-hidden="true"></i>
              </button>
              <button
                v-if="allowSendFile"
                v-b-modal.modal-file-upload
                class="btn btn-link px-2 text-dark"
                :disabled="!selectedChatroom.is_enabled"
              >
                <i class="fa fa-paperclip" aria-hidden="true"></i>
              </button>

              <form @submit.prevent class="flex-fill message-form">
                <b-form-textarea
                  class="message-box"
                  :style="`--lines: ${messageLines}`"
                  :placeholder="
                    selectedChatroom.is_enabled ? '輸入留言' : '聊天室已關閉'
                  "
                  ref="input"
                  v-model="message"
                  v-if="allowSendMessage"
                  :disabled="!selectedChatroom.is_enabled"
                  rows="1"
                  @keypress="handleInputKeypress"
                ></b-form-textarea>

                <button
                  class="btn send"
                  type="button"
                  :disabled="sending || !selectedChatroom.is_enabled"
                  v-if="allowSendMessage"
                  @click="send"
                >
                  <svg
                    width="21"
                    height="18"
                    viewBox="0 0 21 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19.17 6.22991L5.17001 0.449907C4.64029 0.230693 4.05884 0.167851 3.49451 0.26882C2.93018 0.369788 2.4066 0.630339 1.98575 1.01963C1.5649 1.40891 1.2644 1.91064 1.11983 2.4654C0.975262 3.02016 0.992675 3.60473 1.17001 4.14991L2.71001 8.99991L1.13001 13.8499C0.947881 14.3973 0.927422 14.9855 1.07108 15.5442C1.21475 16.1029 1.51644 16.6083 1.94001 16.9999C2.48465 17.505 3.19729 17.79 3.94001 17.7999C4.33474 17.7996 4.72554 17.7215 5.09001 17.5699L19.14 11.7899C19.6872 11.5623 20.1547 11.1778 20.4836 10.6848C20.8125 10.1919 20.988 9.61252 20.988 9.01991C20.988 8.42729 20.8125 7.84795 20.4836 7.35497C20.1547 6.862 19.6872 6.47747 19.14 6.24991L19.17 6.22991ZM4.36001 15.6999C4.18361 15.7727 3.99007 15.7934 3.80225 15.7598C3.61442 15.7261 3.44015 15.6394 3.30001 15.5099C3.168 15.3838 3.07304 15.2239 3.02545 15.0476C2.97785 14.8713 2.97943 14.6854 3.03001 14.5099L4.49001 9.99991H18.22L4.36001 15.6999ZM4.49001 7.99991L3.00001 3.52991C2.94943 3.35446 2.94785 3.16851 2.99545 2.99222C3.04304 2.81594 3.138 2.65606 3.27001 2.52991C3.36412 2.43118 3.47742 2.35275 3.60296 2.29944C3.7285 2.24613 3.86362 2.21906 4.00001 2.21991C4.13406 2.22016 4.26669 2.24737 4.39001 2.29991L18.22 7.99991H4.49001Z"
                      fill="#09101D"
                    />
                  </svg>
                </button>
              </form>
            </template>
          </div>
        </div>
      </div>
      <div v-if="selectedChatroom" class="sidebox">
        <div class="py-3 h-100">
          <div class="profile" v-if="selectedChatroom && false">
            <div class="text-center p-3">
              <b-avatar
                class="my-4"
                size="13rem"
                :src="getChatroomAvatar(selectedChatroom)"
              ></b-avatar>
              <div class="h4 p-2">
                {{ getChatroomTitle(selectedChatroom, true) }}
                <button class="btn h4" v-b-modal.modal-name>
                  <i class="fa fa-pencil" aria-hidden="true"></i>
                </button>
              </div>
              <div class="tags">
                <b-badge
                  v-for="i in tags"
                  :key="i"
                  variant="dark"
                  class="m-1"
                  >{{ i }}</b-badge
                >
                <button class="btn" v-b-modal.modal-tags>
                  <i class="fa fa-pencil" aria-hidden="true"></i>
                </button>
              </div>
            </div>

            <b-list-group
              id="accordionExample"
              flush
              class="border-top border-bottom accordion"
            >
              <b-list-group-item
                role="button"
                class="d-flex justify-content-between align-items-center"
              >
                <div
                  v-b-toggle.collapse-profile
                  class="d-flex justify-content-between align-items-center"
                >
                  記事本
                  <i class="fa fa-chevron-down" aria-hidden="true"></i>
                </div>
                <b-collapse id="collapse-profile" class="py-3">
                  <button class="btn" v-b-modal.modal-note>
                    <i class="fa fa-pencil" aria-hidden="true"></i>
                  </button>
                  {{ customerProfile.note }}
                </b-collapse>
              </b-list-group-item>
              <b-list-group-item role="button">
                <div
                  v-b-toggle.collapse-profile
                  class="d-flex justify-content-between align-items-center"
                >
                  基本資料
                  <i class="fa fa-chevron-down" aria-hidden="true"></i>
                </div>
                <b-collapse id="collapse-profile" class="py-3">
                  <div class="form-group">
                    <label for="InputPhone">行動電話</label>
                    <input
                      type="tel"
                      class="form-control"
                      id="InputPhone"
                      placeholder="請使用 0-9,+,-組合的電話號碼格式"
                    />
                  </div>

                  <div class="form-group">
                    <label for="InputEmail">電子信箱</label>
                    <input
                      type="email"
                      class="form-control"
                      id="InputEmail"
                      placeholder="請輸入電子信箱"
                    />
                  </div>

                  <div class="form-group">
                    <label for="InputBirthday">生日</label>
                    <input
                      type="date"
                      class="form-control"
                      id="InputBirthday"
                      placeholder="日期格式為西元年月日，如 1990-01-01"
                    />
                  </div>

                  <div class="form-group">
                    <label for="InputGender">性別</label>
                    <input
                      type="text"
                      class="form-control"
                      id="InputGender"
                      placeholder="性別"
                    />
                  </div>

                  <div class="form-group">
                    <label for="InputLanguage">語言</label>
                    <input
                      type="text"
                      class="form-control"
                      id="InputLanguage"
                      placeholder="語言"
                    />
                  </div>

                  <div class="form-group">
                    <label for="InputCountry">國家</label>
                    <input
                      type="text"
                      class="form-control"
                      id="InputCountry"
                      placeholder="國家"
                    />
                  </div>

                  <div class="form-group">
                    <label for="InputCity">現居城市</label>
                    <input
                      type="text"
                      class="form-control"
                      id="InputCity"
                      placeholder="現居城市"
                    />
                  </div>
                </b-collapse>
              </b-list-group-item>
            </b-list-group>
          </div>

          <b-tabs
            class="h-100"
            v-if="showMemberListInDashboard || showFileListInDashboard"
          >
            <b-tab
              title="成員"
              title-item-class="pl-2"
              v-if="showMemberListInDashboard"
            >
              <b-list-group v-if="!selectedMember">

                <div class="text-center mt-3 mb-3 loading-spinner" v-if="loading">
                  <b-spinner variant="secondary" small />
                </div>
                <div v-else>
                  <ChatroomAddMember
                    v-if="canShowAddMemberButton"
                    :chatroom="selectedChatroom"
                    :disabled="disabledAddMemberButton"
                    @added="
                      fetchCustomers();
                      fetchStaffs();
                      fetchSingleChatroom();
                    "
                  ></ChatroomAddMember>

                  <b-list-group-item
                    class="d-flex align-items-center border-0"
                    v-for="staff in staffs"
                    :key="staff.id"
                    href="#"
                  >
                    <b-avatar class="mr-1" :src="staff.avatar_url"></b-avatar>
                    <div
                      class="
                        mr-auto
                        font-weight-bold
                        text-nowrap text-truncate
                        pr-2
                      "
                    >
                      {{ staff.name }}
                    </div>

                    <div class="text-nowrap">
                      <b-button
                        v-if="
                          chatroomConfig.invite_staff_in_dashboard_permission_role &&
                          canShowRemoveMemberButton &&
                          (staff.role !== chatroomConfig.invite_staff_in_dashboard_permission_role)
                        "
                        size="xs"
                        pill
                        variant="danger"
                        class="p-1 mr-2"
                        @click="removeMember(staff)"
                      >
                        <i class="fa fa-times m-0"></i>
                      </b-button>
                    </div>

                    <!-- <i class="fa fa-angle-right" aria-hidden="true"></i> -->
                  </b-list-group-item>

                  <b-list-group-item
                    class="d-flex align-items-center border-0"
                    v-for="customer in customers"
                    :key="customer.id"
                    href="#"
                    @click="handleSelectMember(customer.id)"
                  >
                    <b-avatar class="mr-1" :src="customer.avatar_url"></b-avatar>
                    <div
                      class="
                        mr-auto
                        font-weight-bold
                        text-nowrap text-truncate
                        pr-2
                      "
                    >
                      {{ customer.name }}
                    </div>

                    <div class="text-nowrap">
                      <!--移除客戶的功能暫時還不開放-->
                      <!-- <b-button size="xs" pill variant="danger" class="p-1 mr-2" @click="removeMember(customer)">
                        <i class="fa fa-times m-0"></i>
                      </b-button> -->
                      <i class="fa fa-angle-right" aria-hidden="true"></i>
                    </div>
                  </b-list-group-item>
                </div>

              </b-list-group>

              <div class="profile" v-if="selectedMember">
                <button class="btn btn-link" @click="selectedMember = false">
                  <i class="fa fa-angle-left" aria-hidden="true"></i> 回列表
                </button>
                <div class="text-center p-3">
                  <b-avatar
                    class="my-4"
                    size="8rem"
                    :src="customer.avatar_url"
                  ></b-avatar>
                  <div class="h4 p-2 text-left">
                    <h6 class="mb-2">LINE 名稱：{{ customer.name }}</h6>
                    <h6 class="m-0">
                      自訂暱稱：{{ customerProfile.nickname
                      }}<button
                        class="btn m-0"
                        v-b-modal.modal-nickname
                        :hidden="
                          !$permissions.has(
                            $permissions.consts
                              .CHATROOM_LIST_CHAT_MODIFY_CUSTOMER_PROFILE
                          )
                        "
                      >
                        <i class="fa fa-pencil" aria-hidden="true"></i>
                      </button>
                    </h6>
                    <h6 class="m-0">
                      姓名：{{ customerProfile.name
                      }}<button
                        class="btn m-0"
                        v-b-modal.modal-name
                        :hidden="
                          !$permissions.has(
                            $permissions.consts
                              .CHATROOM_LIST_CHAT_MODIFY_CUSTOMER_PROFILE
                          )
                        "
                      >
                        <i class="fa fa-pencil" aria-hidden="true"></i>
                      </button>
                    </h6>
                    <h6 class="mb-2">
                      身份證字號：{{ customerProfile.personal_id
                      }}<button
                        class="btn m-0"
                        v-b-modal.modal-personal-id
                        :hidden="
                          !$permissions.has(
                            $permissions.consts
                              .CHATROOM_LIST_CHAT_MODIFY_CUSTOMER_PROFILE
                          )
                        "
                      >
                        <i class="fa fa-pencil" aria-hidden="true"></i>
                      </button>
                    </h6>
                    <div class="tags">
                      <h6 class="m-0">標籤：</h6>
                      <b-badge
                        v-for="tag in tagsOriginal"
                        :key="tag.id"
                        variant="dark"
                        class="m-1"
                        >{{ tag.name }}</b-badge
                      >
                      <button
                        class="btn"
                        v-b-modal.modal-tags
                        :hidden="
                          !$permissions.has(
                            $permissions.consts
                              .CHATROOM_LIST_CHAT_MODIFY_CUSTOMER_PROFILE
                          )
                        "
                      >
                        <i class="fa fa-pencil" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>
                </div>

                <b-list-group
                  id="accordionExample"
                  flush
                  class="border-top border-bottom accordion"
                >
                  <b-list-group-item role="button">
                    <div
                      v-b-toggle.collapse-note
                      class="d-flex justify-content-between align-items-center"
                    >
                      自訂筆記
                      <i class="fa fa-chevron-down" aria-hidden="true"></i>
                    </div>
                    <b-collapse id="collapse-note" class="py-3">
                      <div
                        style="white-space: break-spaces"
                        v-html="customerProfile.note"
                      ></div>
                      <button
                        class="btn"
                        v-b-modal.modal-note
                        :hidden="
                          !$permissions.has(
                            $permissions.consts
                              .CHATROOM_LIST_CHAT_MODIFY_CUSTOMER_PROFILE
                          )
                        "
                      >
                        <i class="fa fa-pencil" aria-hidden="true"></i>
                      </button>
                    </b-collapse>
                  </b-list-group-item>
                  <b-list-group-item role="button">
                    <div
                      v-b-toggle.collapse-profile
                      class="d-flex justify-content-between align-items-center"
                    >
                      基本資料
                      <i class="fa fa-chevron-down" aria-hidden="true"></i>
                    </div>
                    <b-collapse id="collapse-profile" class="py-3">
                      <div class="form-group">
                        <label for="InputPhone">行動電話</label>
                        <input
                          type="tel"
                          class="form-control"
                          id="InputPhone"
                          placeholder="請使用 0-9,+,-組合的電話號碼格式"
                          v-model="customer.mobile_phone"
                          :disabled="
                            !$permissions.has(
                              $permissions.consts
                                .CHATROOM_LIST_CHAT_MODIFY_CUSTOMER_PROFILE
                            )
                          "
                        />
                      </div>

                      <div class="form-group">
                        <label for="InputEmail">電子信箱</label>
                        <input
                          type="email"
                          class="form-control"
                          id="InputEmail"
                          placeholder="請輸入電子信箱"
                          v-model="customer.email"
                          :disabled="
                            !$permissions.has(
                              $permissions.consts
                                .CHATROOM_LIST_CHAT_MODIFY_CUSTOMER_PROFILE
                            )
                          "
                        />
                      </div>

                      <div class="form-group">
                        <label for="InputBirthday">生日</label>
                        <input
                          type="date"
                          class="form-control"
                          id="InputBirthday"
                          placeholder="日期格式為西元年月日，如 1990-01-01"
                          v-model="customer.birthday"
                          :disabled="
                            !$permissions.has(
                              $permissions.consts
                                .CHATROOM_LIST_CHAT_MODIFY_CUSTOMER_PROFILE
                            )
                          "
                        />
                      </div>

                      <div class="form-group">
                        <label for="InputGender">性別</label>
                        <input
                          type="text"
                          class="form-control"
                          id="InputGender"
                          placeholder="性別"
                          v-model="customer.sexual"
                          :disabled="
                            !$permissions.has(
                              $permissions.consts
                                .CHATROOM_LIST_CHAT_MODIFY_CUSTOMER_PROFILE
                            )
                          "
                        />
                      </div>

                      <div class="form-group" v-if="false">
                        <label for="InputLanguage">語言</label>
                        <input
                          type="text"
                          class="form-control"
                          id="InputLanguage"
                          placeholder="語言"
                          v-model="customer.language"
                          :disabled="
                            !$permissions.has(
                              $permissions.consts
                                .CHATROOM_LIST_CHAT_MODIFY_CUSTOMER_PROFILE
                            )
                          "
                        />
                      </div>

                      <div class="form-group" v-if="false">
                        <label for="InputCountry">國家</label>
                        <input
                          type="text"
                          class="form-control"
                          id="InputCountry"
                          placeholder="國家"
                          :disabled="
                            !$permissions.has(
                              $permissions.consts
                                .CHATROOM_LIST_CHAT_MODIFY_CUSTOMER_PROFILE
                            )
                          "
                        />
                      </div>

                      <div class="form-group" v-if="false">
                        <label for="InputCity">現居城市</label>
                        <input
                          type="text"
                          class="form-control"
                          id="InputCity"
                          placeholder="現居城市"
                          :disabled="
                            !$permissions.has(
                              $permissions.consts
                                .CHATROOM_LIST_CHAT_MODIFY_CUSTOMER_PROFILE
                            )
                          "
                        />
                      </div>

                      <button
                        class="btn btn-success"
                        @click="handleClickSaveCustomer"
                        :hidden="
                          !$permissions.has(
                            $permissions.consts
                              .CHATROOM_LIST_CHAT_MODIFY_CUSTOMER_PROFILE
                          )
                        "
                      >
                        儲存
                      </button>
                    </b-collapse>
                  </b-list-group-item>
                </b-list-group>
              </div>
            </b-tab>
            <b-tab
              title="檔案"
              title-item-class="pr-2"
              @click="fetchFileList"
              v-if="showFileListInDashboard"
            >
              <b-list-group>
                <div class="text-center mt-3 mb-3 loading-spinner" v-if="loading">
                  <b-spinner variant="secondary" small />
                </div>
                <div v-else>
                  <b-list-group-item
                    class="d-flex align-items-center border-0"
                    v-for="(file, i) in fileList"
                    :key="i"
                    :href="catFileType(file.mime) === 'jpg'? '': file.url"
                  >
                    <img
                      v-if="catFileType(file.mime) === 'csv'"
                      class="mr-2 file-type-icon" src="@/assets/images/file_type_icons/csv.png"
                    >
                    <img
                      v-if="catFileType(file.mime) === 'doc'"
                      class="mr-2 file-type-icon" src="@/assets/images/file_type_icons/doc.png"
                    >
                    <img
                      v-if="catFileType(file.mime) === 'file'"
                      class="mr-2 file-type-icon" src="@/assets/images/file_type_icons/file.png"
                    >
                    <img
                      v-if="catFileType(file.mime) === 'jpg'"
                      class="mr-2 file-type-icon" :src="file.url"
                      @click="currentImg = file.url"
                      v-b-modal.modal-showImg
                    >
                    <img
                      v-if="catFileType(file.mime) === 'pdf'"
                      class="mr-2 file-type-icon" src="@/assets/images/file_type_icons/pdf.png"
                    >
                    <img
                      v-if="catFileType(file.mime) === 'ppt'"
                      class="mr-2 file-type-icon" src="@/assets/images/file_type_icons/ppt.png"
                    >
                    <img
                      v-if="catFileType(file.mime) === 'txt'"
                      class="mr-2 file-type-icon" src="@/assets/images/file_type_icons/txt.png"
                    >
                    <img
                      v-if="catFileType(file.mime) === 'xls'"
                      class="mr-2 file-type-icon" src="@/assets/images/file_type_icons/xls.png"
                    >
                    <img
                      v-if="catFileType(file.mime) === 'zip'"
                      class="mr-2 file-type-icon" src="@/assets/images/file_type_icons/zip.png"
                    >

                    <div class="d-flex flex-column lh-sm-0 text-truncate">
                      <div class="mr-auto font-weight-bold">
                        {{ file.name }}
                      </div>
                      <div class="small text-muted">{{ file.sender.name }}</div>
                      <div class="small text-muted">{{ formatDateD(file.created_at) }}</div>
                    </div>
                  </b-list-group-item>
                </div>
              </b-list-group>
              <b-pagination
                class="separated mb-2 mb-md-0 align-self-center"
                v-model="currentPage"
                :total-rows="total"
                :per-page="perPage"
                @change="handleChangePage"
              ></b-pagination>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </b-card>
    <ImageLightBox ref="lightbox" />
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import "bootstrap-vue/dist/bootstrap-vue.css";
import chatroomApi from "@/apis/chatrooms";
import customerApi from "@/apis/customer";
import moduleApi from "@/apis/module";
import moment from "moment";
import format from "date-fns/format";
import ChatroomListItem from "@/components/Page/Chatroom/ChatroomListItem.vue";
import ChatroomAddMember from "@/pages/Dashboard/Chatrooms/ChatroomAddMember.vue";
import {
  getChatroomAvatar,
  getChatroomTitle,
  formatDate,
  getStaffAvatar,
  getCustomerAvatar,
} from "@/utils/chatroomHelper.js";
import Echo from "laravel-echo";
import store from "@/store";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import chatoomMixin from "@/mixins/chatroom";
import {
  CHATROOM_STATUS,
  chatroomStatusTextMapping,
  chatroomStatusVariantMapping,
} from "@/pages/Dashboard/Chatrooms/Chatroom.utils";
import _ from 'lodash';
import ChatroomMultiselect from "@/components/Page/Chatroom/ChatroomMultiselect.vue";
global.Pusher = require("pusher-js");
export default {
  mixins: [chatoomMixin],
  components: {
    ChatroomListItem,
    ChatroomAddMember,
    ChatroomMultiselect,
  },
  data() {
    return {
      currentImg: "",
      bottom: true,
      loading: false,
      sending: false,
      uploading: false,
      selectedChatroomID: "",
      selectedMember: false,
      selectedBranch: null,
      chatrooms: [],
      staffs: [],
      customer: null,
      chatroomConfig: null, // module_config
      customerProfile: {
        nickname: null,
        name: null,
        personal_id: null,
        note: null,
      },
      customers: [{}],
      messages: [],
      filters: [
        { id: "所有收件匣", icon: "fa fa-inbox" },
        { id: "未指派", icon: "fa fa-clock-o" },
        { id: "完成", icon: "fa fa-check-circle-o" },
        { id: "所有已指派", icon: "fa fa-files-o" },
        { id: "被指派", icon: "fa fa-sign-in" },
        { id: "聊天機器人", icon: "fa fa-android" },
        { id: "垃圾匣", icon: "fa fa-trash-o" },
        { id: "顧客已封鎖", icon: "fa fa-ban" },
      ],
      tags: ["買香水", "女性", "20歲", "永和", "嬰兒用品"],
      tagsOriginal: [],
      inputTag: null,
      inputNickname: null,
      keyword: null,
      message: null,
      isMessageSearching: false,
      m_pos: null,
      leftWidth: null,
      unread: false,
      nextCursor: null,
      echo: null,
      channel: null,
      stateBag: [],
      allowSendMessage: false,
      showMemberListInDashboard: false,
      showFileListInDashboard: false,
      allowSendImage: false,
      allowSendFile: false,
      fileList: [],
      CHATROOM_STATUS,
      chatroomStatusTextMapping,
      chatroomStatusVariantMapping,
      perPage: 10,
      currentPage: 1,
      total: 0,
      initialized: false,
      chatroomNextCursor: null,
      selectedBranchID: "",
      changingBranch: false,
      selectedImageUrls: [],
      allowFilterCategory: false,
      changingFilter:false,
      everyMinFetchChatroomTimer:null,
      categoryFilterOptions: [],
      selectedCategoryFilter: [],
      selectedStatus: [],
      statusOptions: [
        { text: '待處理', value: 'pending', variant: "danger" },
        { text: '處理中', value: 'doing', variant: "warning" },
        { text: '處理完畢', value: 'done', variant: "primary" },
        { text: '未設定', value: 'not-setting', variant: "secondary" },
      ],
      allowFilterStatus: false,
      selectedChatroom: null,
      keywordFilter: false,
    };
  },
  computed: {
    ...mapState("general", {
      newestNotification: (state) => state.newestNotification,
      myBranches: (state) => state.myBranches,
    }),
    ...mapGetters("general", ["branchOptions"]),
    staffId() {
      return store.state.auth.user.id;
    },
    chatroomId() {
      return this.selectedChatroom?.id;
    },
    senderId() {
      return store.state.auth.user.id;
    },
    senders() {
      return this.selectedChatroom?.senders;
    },
    selectedChatroomStatus() {
      return this.selectedChatroom?.status;
    },
    selectedChatroomStatusName() {
      return chatroomStatusTextMapping[this.selectedChatroomStatus] ?? '未設定'
    },
    selectedChatroomStatusVariant() {
      return chatroomStatusVariantMapping[this.selectedChatroomStatus] ?? 'secondary'
    },
    // selectedChatroom() {
    //   return this.chatrooms.find(
    //     (chatroom) => chatroom.id === this.selectedChatroomID
    //   );
    // },
    orderedChatrooms() {
      return this.sortingChatrooms();
    },
    canShowPinButton() {
      if (!this.chatroomConfig) {
        return false;
      }
      return this.chatroomConfig.allow_staff_pin_in_dashboard ?? false;
    },
    canShowStatusButtons() {
      if (!this.chatroomConfig) {
        return false;
      }
      return this.chatroomConfig.allow_staff_set_status_in_dashboard ?? false;
    },
    canShowBranchSelector() {
      return this.$permissions.has(
        this.$permissions.consts.CHATROOM_LIST_CHAT_BRANCH_LIST
      );
    },
    canShowAddMemberButton() {
      if (!this.chatroomConfig) return false;
      const sender = this.selectedChatroom?.senders.find(
        (s) => s.id === this.staffId
      );
      if (!sender) return false;
      return (
        this.chatroomConfig.invite_staff_in_dashboard_permission_role === sender.role &&
        (this.chatroomConfig.allow_invite_staff_in_dashboard ?? false)
      );
    },
    // 是否 disabled 加入成員的按鈕
    disabledAddMemberButton() {
      if (!this.chatroomConfig) return true;
      const matchRoles = this.selectedChatroom?.senders.filter(
        (s) => s.role === this.chatroomConfig.staff_invite_default_role
      );
      // 如果符合 default_role 的人數大於等於最高邀請上限，則 disabled
      return matchRoles.length >= this.chatroomConfig.staff_invite_max_count
    },
    canShowRemoveMemberButton() {
      if (!this.chatroomConfig) return false;
      const sender = this.selectedChatroom?.senders.find(
        (s) => s.id === this.staffId
      );
      if (!sender) return false;
      return (
        this.chatroomConfig.invite_staff_in_dashboard_permission_role === sender.role &&
        (this.chatroomConfig.allow_invite_staff_in_dashboard ?? false)
      );
    },
    isSelectedChatroomPinned() {
      return !!this.selectedChatroom?.extensions[0]?.pinned_at;
    },
    canShowChatReply() {
      // 是否有亂入權限
      const notSenderReply = this.$permissions.has(
        this.$permissions.consts.CHATROOM_LIST_CHAT_NOT_SENDER_REPLY
      );
      // 有 繼續判斷能不能聊天
      if (notSenderReply) {
        return this.$permissions.has(
          this.$permissions.consts.CHATROOM_LIST_CHAT_REPLY
        );
      } else {
        // 沒有 看自己是否在聊天室內 不在也不能聊天
        const sender = this.selectedChatroom?.senders.find(
          (s) => s.id === this.staffId
        );
        if (sender) {
          return this.$permissions.has(
            this.$permissions.consts.CHATROOM_LIST_CHAT_REPLY
          );
        } else {
          return false;
        }
      }
    },
    showIndicator() {
      return this.scrollTop < -100;
    },
    canShowMultiDownload() {
      if (!this.chatroomConfig) {
        return false;
      }
      return this.chatroomConfig.allow_batch_download_images_in_dashboard ?? false;
    },
    selectedCategoryValue() {
      return this.selectedCategoryFilter.map(({ value }) => value).join(",");
    },
    filterCategoryOptions() {
      return this.categoryFilterOptions.map((option) => {
        return {
           value: option.key,
           text: option.display_name
        };
      });
    },
    selectedStatusValue() {
      return this.selectedStatus.map(({ value }) => value).join(",");
    },
  },
  created() {
    this.init();
    this.keywordSearchDebounced = _.debounce(async () => {
      this.keywordFilter = true
      await this.fetchChatrooms()
      this.keywordFilter = false
    }, 1000)
  },
  methods: {
    getChatroomTitle,
    getChatroomAvatar,
    formatDate,
    getStaffAvatar,
    getCustomerAvatar,
    format,
    keywordSearchDebounced () {
    },
    async init() {
      this.initialized = false;
      this.initializeEcho();
      this.fetchChatroomConfig();
      await this.firstFetchChatrooms();
      this.regularFetchChatroomTrigger()
      this.initialized = true
    },
    /**
     * 初始撈聊天室邏輯
     *
     * CHATROOM_LIST_CHAT_ORG權限判斷
     *  有 -> 撈全部
     *  無 -> 往下看 CHATROOM_LIST_CHAT_BRANCH_LIST
     *
     * CHATROOM_LIST_CHAT_BRANCH_LIST 權限判斷
     *  有 -> 用第一個分店撈
     *  無 -> 撈自己的
     */
    async firstFetchChatrooms() {
      if (
        this.$permissions.has(this.$permissions.consts.CHATROOM_LIST_CHAT_ORG)
      ) {
        this.listenForOrgChannelEvents(store.state.general.organization.id);
        await this.fetchChatrooms();
        this.defaultCategoryFilter();
        return;
      }
      if (
        this.$permissions.has(
          this.$permissions.consts.CHATROOM_LIST_CHAT_BRANCH_LIST
        )
      ) {
        if (this.branchOptions[0].value ?? null) {
          this.selectedBranch = this.branchOptions[0].value;
          this.selectedBranchID = this.selectedBranch;
          this.listenForBranchChannelEvents(this.selectedBranch);
          await this.fetchChatrooms(this.selectedBranch);
          this.defaultCategoryFilter();
          return;
        }
      }
      this.listenForStaffChannelEvents();
      await this.fetchChatrooms();
      this.defaultCategoryFilter();
    },
    async handleChangeSelectedBranch(selectedBranch) {
      if (this.changingBranch) return;
      this.changingBranch = true;
      this.selectedBranch = selectedBranch;
      this.messages = [];
      this.echo.leave(`branch.${this.selectedBranchID}`);
      this.listenForBranchChannelEvents(this.selectedBranch);
      this.selectedBranchID = this.selectedBranch;
      await this.fetchChatrooms(this.selectedBranch);
      this.$nextTick(() => this.$refs.chatroomList.scrollTo(0, 0));
      this.changingBranch = false;
    },
    async fetchChatroomConfig() {
      let { data } = await moduleApi.getModuleConfig({
        module_code: "chatroom",
      });
      this.chatroomConfig = data;
    },
    async selectChatroom(chatroom) {
      if (chatroom.id === this.selectedChatroomID) {
        return;
      }
      while (this.loading) {
        await this.sleep(100);
      }
      this.leaveChannel();
      this.selectedChatroomID = chatroom.id;
      this.selectedChatroom = this.chatrooms.find(
        (chatroom) => chatroom.id === this.selectedChatroomID
      );
      this.selectedMember = false;
      this.fetchSingleChatroom();
      this.fetchMessages();
      this.fetchFileList();
      this.clearTypingSenders();
      this.clearTimers();
      this.connectToChannel();
      this.listenForChannelEvents();
      this.fetchCustomers();
      this.fetchStaffs();
      this.resetCurrentCustomer();
      this.read();
      this.message = "";
      this.stateBag = [];
    },
    // 離開並重置當前所選聊天室的狀態
    async resetSelectedChatroom() {
      this.leaveChannel();
      this.selectedChatroomID = null;
      this.selectedMember = false;
      this.messages = [];
      this.staffs = [];
      this.customers = [];
      this.stateBag = [];
      this.message = "";
      this.nextCursor = null;
      this.selectedImageUrls = [];
    },
    resetCurrentCustomer() {
      this.customer = null;
      this.customerProfile.nickname = null;
      this.customerProfile.name = null;
      this.customerProfile.personal_id = null;
      this.customerProfile.note = null;
      this.inputTag = null;
      this.inputNickname = null;
    },
    async handleMessageReceivedUpdateTab(event) {
      let { message, chatroom, sender } = event;
      let targetChatroom = this.chatrooms.find((c) => c.id === chatroom.id)
      if (targetChatroom) {
        this.increaseUnreadCount(chatroom, sender)
      }
      let newChatroom = null
      if (!targetChatroom) {
        let response = await chatroomApi.getSingleChatrooms(chatroom.id);
        //如果沒有 要判斷一下新的Chatroom 是否符合 User Category
        const category = response.data.data.category
        const status = response.data.data.status
        if (this.categoryFilterOptions.find((o) => o.key === category)) {
          // 追加判斷目前的篩選狀態 status & category
          if (this.selectedStatus.find((s) => s.value === status) && this.selectedCategoryFilter.find((c) => c.value === category)) {
              newChatroom = response.data.data
          } else if (this.selectedStatus.find((s) => s.value === status) && !this.selectedCategoryFilter) {
            newChatroom = response.data.data
          } else if (this.selectedCategoryFilter.find((c) => c.value === category) && !this.selectedStatus) {
            newChatroom = response.data.data
          }
          //都篩選條件 直接新增
          if (!this.selectedStatus && !this.selectedCategoryFilter) {
            newChatroom = response.data.data
          }
        }
      } else {
        Object.assign(targetChatroom, { message: message });
      }
      if (newChatroom) {
        this.chatrooms.unshift(newChatroom)
      }
      this.sortingChatrooms();
    },
    increaseUnreadCount(chatroom, sender) {
      if (
        this.selectedChatroomID === chatroom.id ||
        this.senderId === sender.id
      ) {
        return;
      }
      let targetSender = this.chatrooms
        .find((c) => c.id === chatroom.id)
        .senders.find((s) => s.id === this.staffId);
      if (targetSender) {
        let count = 0;
        if (!("unread_count" in targetSender)) {
          count = 1;
        }
        count = targetSender.unread_count + 1;
        Object.assign(targetSender, { unread_count: count });
      }
    },
    async handleChatroomCreated(event) {
      let { chatroom } = event;
      let response = await chatroomApi.getSingleChatrooms(chatroom.id);
      let newChatroom = response.data.data;
      if (this.chatrooms.find((c) => c.id == newChatroom.id)) {
        return;
      }
      newChatroom.is_new_created = true;
      this.chatrooms.push(newChatroom);
    },
    async handleSenderAdded(event) {
      this.fetchChatrooms();
      if (event.chatroom.id == this.selectedChatroomID) {
        this.fetchSingleChatroom();
        this.fetchCustomers();
        this.fetchStaffs();
      }
    },
    async handleSenderRemoved(event) {
      this.fetchChatrooms();
      if (event.chatroom.id == this.selectedChatroomID) {
        this.resetSelectedChatroom();
      }
    },
    async fetchSingleChatroom() {
      let response = await chatroomApi.getSingleChatrooms(
        this.selectedChatroomID
      );
      const oldChatroom = this.chatrooms.find(
        (chatroom) => chatroom.id === response.data.data.id
      );
      // 這邊做個處理 如果sender回來有未讀 則預設就顯示0
      let newChatroom = response.data.data;
      let sender = newChatroom.senders.find((s) => s.id === this.staffId);
      if (sender) {
        sender.unread_count = 0;
      }
      Object.assign(oldChatroom, newChatroom);
    },
    async fetchChatrooms(branchId = null) {
      let payload = this.preparePayload()
      if (branchId) {
        payload.branch_id = branchId
      }
      let response = await chatroomApi.getChatrooms(payload);
      this.chatrooms = response.data.data;
      this.chatroomNextCursor = response.data.meta.nextCursor;
      this.allowSendMessage =
        response.data.config?.allow_staff_send_message_in_dashboard ?? false;
      this.allowSendFile =
        response.data.config?.allow_staff_send_files_in_dashboard ?? false;
      this.allowSendImage =
        response.data.config?.allow_staff_send_images_in_dashboard ?? false;
      this.showMemberListInDashboard =
        response.data.config?.show_member_list_in_dashboard ?? false;
      this.showFileListInDashboard =
        response.data.config?.show_file_list_in_dashboard ?? false;
      this.allowFilterCategory =
        response.data.config?.show_category_filter_in_dashboard ?? false;
      this.allowFilterStatus = response.data.config?.show_status_filter_in_dashboard ?? false;
      // get Options
      this.categoryFilterOptions = response.data.options?.categoryFilter ?? [];
    },
    async fetchMoreChatrooms(branchId = null) {
      if (!this.chatroomNextCursor) return;
      let payload = this.preparePayload()
      if (branchId) {
        payload.branch_id = branchId
      }
      payload.cursor = this.chatroomNextCursor
      payload.count = 20
      if (this.keyword) {
        payload.keyword = this.keyword
      }
      let response = await chatroomApi.getChatrooms(payload);
      const newChatrooms = response.data.data.filter((chatroom) => {
        return !this.chatrooms.map((c) => c.id).includes(chatroom.id);
      });
      if (newChatrooms.length) {
        newChatrooms.forEach((c) => {
          this.chatrooms.push(c);
        });
      }
      this.chatroomNextCursor = response.data.meta.nextCursor;
    },
    async fetchCustomers() {
      const { data } = await chatroomApi.getCustomers(this.selectedChatroom);
      this.customers = data.data;
    },
    async fetchStaffs() {
      const { data } = await chatroomApi.getStaffs(this.selectedChatroom);
      this.staffs = data.data;
    },
    async fetchMessages() {
      this.messages = [];
      if (this.loading) return;
      this.loading = true;
      const { messages, nextCursor } = await this.load();
      this.messages = messages;
      this.nextCursor = nextCursor;
      this.loading = false;
    },
    async load(cursor = null, count = 10) {
      let messages, nextCursor;
      try {
        let response = await chatroomApi.getMessages({
          chatroom: this.selectedChatroom,
          cursor,
          count,
        });
        messages = response.data.data;
        nextCursor = response.data.meta.nextCursor;
      } catch (e) {
        console.error(e);
      }
      return { messages, nextCursor };
    },
    async loadMore() {
      if (!this.nextCursor) return;
      if (this.loading) return;
      this.loading = true;
      const { messages, nextCursor } = await this.load(this.nextCursor);
      this.messages = [...this.messages, ...messages];
      this.nextCursor = nextCursor;
      this.loading = false;
    },
    handleInputKeypress(e) {
      if (e.key !== "Enter") {
        return;
      }
      if (e.shiftKey === true) {
        return;
      }
      e.preventDefault();
      this.send();
    },
    async send() {
      const message = this.message.trim();
      if (this.sending || message == "") return;
      this.sending = true;
      const state = uuidv4();
      this.stateBag.push(state);
      this.message = "";
      this.messages.unshift(this.buildMessageWithState({ state, message }));
      try {
        await chatroomApi.sendMessage({
          chatroom: this.selectedChatroom,
          message,
          state,
        });
        this.read();
      } catch (error) {
        let errorMessage = error.response?.data?.message ?? null;
        this.$swal("發送訊息失敗", errorMessage, "error");
        await this.fetchMoreChatrooms(this.selectedBranch);
        this.resetSelectedChatroom();
      }
      this.sending = false;
    },
    buildMessageWithState({ state, message }) {
      return {
        state,
        content: message,
        created_at: new Date().toISOString(),
        sender: {
          avatar_url: store.state.auth.user.avatar_url,
          id: store.state.auth.user.id,
          name: store.state.auth.user.name,
          type: "staff",
        },
      };
    },
    async read() {
      // 如果我是聊天室裡的人 我才打read 這隻api
      if (this.selectedChatroom.senders.find((s) => s.id === this.staffId)) {
        this.reduceUnreadCount();
        await chatroomApi.markAsRead({ chatroom: this.selectedChatroom });
      }
    },
    reduceUnreadCount() {
      const sender = this.chatrooms
        .find((c) => c.id === this.selectedChatroom.id)
        .senders.find((s) => s.id === this.staffId);
      if (sender) {
        Object.assign(sender, { unread_count: 0 });
      }
    },
    isSender(message) {
      return message.sender.type === "staff";
    },
    checkStrike(messages, idx) {
      if (idx == messages.length - 1) {
        return true;
      } else if (idx + 1 < messages.length) {
        let now = new Date(messages[idx + 1].created_at);
        let date = new Date(messages[idx].created_at);
        return !(
          now.getFullYear() == date.getFullYear() &&
          now.getMonth() == date.getMonth() &&
          now.getDate() == date.getDate()
        );
      } else return false;
    },
    resizeStart(e) {
      this.leftWidth = this.$refs.chatlist.getBoundingClientRect().width;
      this.m_pos = e.clientX;
      document.onselectstart = () => {
        return false;
      };
      document.addEventListener("mousemove", this.resizeMove);
      document.addEventListener("mouseup", this.resizeEnd);
    },
    resizeMove(e) {
      const dx = e.clientX - this.m_pos;
      const newLeftWidth =
        ((this.leftWidth + dx) * 100) /
        this.$refs.chatlist.parentNode.getBoundingClientRect().width;
      this.$refs.chatlist.style.width = `${newLeftWidth}%`;
    },
    resizeEnd() {
      document.removeEventListener("mousemove", this.resizeMove);
      document.onselectstart = () => {
        return true;
      };
    },
    scrollToBottom() {
      this.$refs.messages.scrollTo(0, this.$refs.messages.scrollHeight);
    },
    handleScroll(e) {
      let chatbox = e.target;
      const scrollTop = Math.floor(chatbox.scrollTop);
      this.scrollTop = scrollTop;
      if (
        chatbox.scrollHeight - (chatbox.offsetHeight + Math.abs(scrollTop)) <
        100
      ) {
        this.loadMore();
      } else if (scrollTop === 0) {
        //scroll to bottom
        this.unread = false;
        this.bottom = true;
        this.read();
      } else {
        this.bottom = false;
      }
    },
    async handleChatroomListScroll(event) {
      let chatbox = event.target;
      const scrollTop = Math.floor(chatbox.scrollTop);
      this.chatroomListScrollTop = scrollTop;
      if (
        chatbox.scrollHeight - (chatbox.offsetHeight + Math.abs(scrollTop)) <
        150
      ) {
        if (!this.loading) {
          this.loading = true;
          await this.fetchMoreChatrooms(this.selectedBranch);
          this.loading = false;
        }
      }
    },
    initializeEcho() {
      const echo = new Echo({
        broadcaster: "pusher",
        key: process.env.VUE_APP_SOKETI_APP_KEY,
        wsHost: process.env.VUE_APP_SOKETI_HOST,
        wsPort: process.env.VUE_APP_SOKETI_PORT,
        wssPort: process.env.VUE_APP_SOKETI_PORT,
        forceTLS: false,
        encrypted: true,
        disableStats: true,
        enabledTransports: ["ws", "wss"],
        authorizer: (channel) => {
          return {
            authorize(socketId, callback) {
              const orgCode = store.state.general.currentOrgCode;
              const token = store.state.auth.token;
              const authEndpoint = `${process.env.VUE_APP_API_BASE_URL}/${orgCode}/admin/broadcasting/auth`;
              axios
                .post(
                  authEndpoint,
                  {
                    socket_id: socketId,
                    channel_name: channel.name,
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then((response) => callback(false, response.data))
                .catch((error) => callback(true, error));
            },
          };
        },
      });
      this.echo = echo;
    },
    connectToChannel() {
      this.channel = this.echo.private(this.channelName);
    },
    leaveChannel() {
      this.echo.leave(this.channelName);
    },
    async handleMessageReceived(event) {
      let { message, sender, state, chatroom } = event;
      if (chatroom.id !== this.selectedChatroomID) {
        return;
      }
      //
      const customer = this.customers.find((s) => {
        return s.id === sender.id
      })
      if (customer) {
        message.sender = customer
      }
      const index = this.stateBag.indexOf(state);
      const messagesBox = this.$refs.messages;
      const originalScrollHeight = messagesBox.scrollHeight;
      if (index !== -1) {
        const compensation = this.messages.find((message) => {
          return message.state === state;
        });
        compensation.id = message.id;
        compensation.content = message.content;
        compensation.type = message.type;
        compensation.meta = message.meta;
        this.stateBag.splice(index, 1);
        return;
      }
      this.messages.unshift({ sender, ...message });
      this.$nextTick(() => {
        if (this.showIndicator) {
          return messagesBox.scrollTo(
            0,
            messagesBox.scrollTop -
              (messagesBox.scrollHeight - originalScrollHeight)
          );
        }
        this.scrollToBottom();
      });
      if (!this.bottom) {
        return (this.unread = true);
      }
      this.read();
    },
    handleSelectMember(customerId) {
      const me = this;
      const promise1 = this.fetchCustomer(customerId);
      const promise2 = this.fetchCustomerProfile(customerId);
      this.fetchCustomerTags(customerId);
      Promise.all([promise1, promise2]).then(function () {
        if (!me.customerProfile.nickname) {
          me.customerProfile.nickname = me.customer.name;
        }
        me.inputNickname = me.customerProfile.nickname;
        me.$forceUpdate();
      });
    },
    async fetchCustomer(customerId) {
      const { data } = await customerApi.getCustomer(customerId);
      this.customer = data;
      this.customer.language = this.customer.data?.language;
      this.selectedMember = true;
    },
    async fetchCustomerProfile(customerId) {
      const { data } = await customerApi.getCustomerProfiles(customerId);
      this.customerProfile = data.data;
    },
    async fetchCustomerTags(customerId) {
      const { data } = await customerApi.getCustomerTags(customerId);
      this.tagsOriginal = data.data;
      this.tags = Array.from(data.data);
    },
    async handleUpdateCustomerProfile() {
      await customerApi.storeCustomerProfiles(this.customer.id, {
        ...this.customerProfile,
        nickname: this.inputNickname,
      });
      await this.fetchCustomerProfile(this.customer.id);
      // await this.fetchCustomers() //看有沒有要及時改顯示留言再決定要打開與否
      this.inputNickname = this.customerProfile.nickname;
    },
    async handleSaveNote() {
      await customerApi.storeCustomerProfiles(this.customer.id, {
        note: this.customerProfile.note,
      });
    },
    async handleClickChangeStatus(status) {
      if (status === 'not-setting') {
        status = null
      }
      if (this.selectedChatroom.status == status) {
        await chatroomApi.update(this.selectedChatroom.id, {
          status: null,
        });
      } else {
        this.selectedChatroom.status = status
        await chatroomApi.update(this.selectedChatroom.id, {
          status,
        });
      }
      this.fetchSingleChatroom()
    },
    async handleClickPin() {
      if (this.isSelectedChatroomPinned) {
        await chatroomApi.unpin(this.selectedChatroom.id);
      } else {
        await chatroomApi.pin(this.selectedChatroom.id);
      }
      this.fetchSingleChatroom()
      // this.fetchChatrooms(this.selectedBranch);
    },
    defaultCategoryFilter() {
      this.selectedCategoryFilter = this.categoryFilterOptions.filter((option)=> {
          return option.default_selected
        }).map((option) => {
          return {
             value: option.key,
             text: option.display_name
          };
        });
    },
    // 逗號也要當作tag 分隔
    handleKeyUpInputTag(event) {
      if (event.key == ",") {
        this.inputTag = this.inputTag.slice(0, -1);
        this.handleEnterInputTag();
      }
    },
    handleChangePage() {
      this.$nextTick(() => {
        this.fetchFileList();
      });
    },
    handleEnterInputTag() {
      if (!this.inputTag) {
        return;
      }
      let isExisted = false;
      for (let i = 0; i < this.tags.length; i++) {
        if (this.tags[i].name == this.inputTag) {
          isExisted = true;
          break;
        }
      }
      if (isExisted) {
        this.inputTag = null;
        return;
      }
      this.tags.push({
        name: this.inputTag,
      });
      this.inputTag = null;
    },
    handleClickRemoveTag(index) {
      this.tags.splice(index, 1);
    },
    catFileType(fileType) {
      let type = "file";
      const jpegTypes = ["jpeg", "jpg", "png"];
      jpegTypes.forEach((jpegType) => {
        fileType.includes(jpegType) ? (type = "jpg") : "";
      });
      const docTypes = ["doc", "docx"];
      docTypes.forEach((docType) => {
        fileType.includes(docType) ? (type = "doc") : "";
      });
      const pdfTypes = ["pdf"];
      pdfTypes.forEach((pdfType) => {
        fileType.includes(pdfType) ? (type = "pdf") : "";
      });
      const csvTypes = ["csv"];
      csvTypes.forEach((csvType) => {
        fileType.includes(csvType) ? (type = "csv") : "";
      });
      const pptTypes = ["ppt", "pptx"];
      pptTypes.forEach((pptType) => {
        fileType.includes(pptType) ? (type = "ppt") : "";
      });
      const txtTypes = ["txt"];
      txtTypes.forEach((txtType) => {
        fileType.includes(txtType) ? (type = "txt") : "";
      });
      const xlsTypes = ["xls", "xlsx"];
      xlsTypes.forEach((xlsType) => {
        fileType.includes(xlsType) ? (type = "xls") : "";
      });
      const zipTypes = ["zip"];
      zipTypes.forEach((zipType) => {
        fileType.includes(zipType) ? (type = "zip") : "";
      });
      return type;
    },
    handleCloseUpdateNickname() {
      this.inputNickname = this.customerProfile.nickname;
    },
    async handleSaveTags() {
      let payload = {
        tags: this.tags,
      };
      await customerApi.setCustomerTags(this.customer.id, payload);
      this.fetchCustomerTags(this.customer.id);
    },
    async handleUpload(files) {
      if (this.uploading) {
        return;
      }
      for (const file in files) {
        if (file.size > 10 * 1024 * 1024) {
          this.$refs.fileUploader?.reset()
          this.$refs.imageUploader?.reset()
          return this.$bvToast.toast('檔案必須小於 10mb', {
            variant: 'danger',
            solid: true,
          })
        }
      }
      this.uploading = true;
      try {
        const state = uuidv4()
        this.stateBag.push(state)
        this.messages.unshift(this.buildMessageWithState({ state, message: '上傳中...' }))
        const filesInfo = files.map(this.getFileinfo)
        const { files: uploadInfo } = await chatroomApi.upload(this.selectedChatroom.id, filesInfo)
        const requests = uploadInfo.map(({ fileId, uploadURL }, index) => {
          return this.createUploader({ id: fileId, url: uploadURL, file: files[index] }).start()
        })
        await Promise.all(requests)
        await chatroomApi.markAsUploaded(this.chatroomId, { state, files: uploadInfo })
        this.$refs.fileModal?.hide();
        this.$refs.imageModal?.hide();
      } catch (e) {
        console.error('Upload error:', e)
      } finally {
        this.$refs.fileUploader?.reset()
        this.$refs.imageUploader?.reset()
        this.uploading = false
      }
    },
    async handleClickSaveCustomer() {
      let params = {
        mobile_phone: this.customer.mobile_phone,
        email: this.customer.email,
        birthday: this.customer.birthday,
        sexual: this.customer.sexual,
      };
      try {
        await customerApi.storeCustomer(this.customer.id, params);
        this.$swal.fire({
          icon: "success",
          text: "儲存成功",
        });
      } catch (err) {
        let errWording = "";
        for (let errKey in err.response.data.message) {
          errWording += err.response.data.message[errKey].join(",");
        }
        this.$swal("失敗", errWording, "error");
      }
    },
    async fetchFileList() {
      try {
        let params = {
          page: this.currentPage,
          per_page: this.perPage,
        };
        const { data } = await chatroomApi.getFileList({
          chatroom: this.selectedChatroom,
          params: params,
        });
        this.fileList = data.data;
        this.total = data.meta.total;
        this.perPage = data.meta.per_page;
      } catch (e) {
        console.error(e);
      }
    },
    formatDateD(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD HH:mm");
      }
    },
    sortingChatrooms() {
      const [pinned, unpinned] = [...this.chatrooms]
        .sort(
          (a, b) =>
            new Date(b.message?.updated_at).getTime() -
            new Date(a.message?.updated_at).getTime()
        )
        .reduce(
          (prev, curr) => {
            const [pinned, unpinned] = prev;
            if (curr?.extensions[0]?.pinned_at) {
              return [[...pinned, curr], [...unpinned]];
            }
            return [[...pinned], [...unpinned, curr]];
          },
          [[], []]
        );
      let chatrooms = [...pinned, ...unpinned];
      let newChatroom = chatrooms.filter((c) => c.is_new_created);
      if (newChatroom) {
        let newUnpinned = unpinned.filter((chatroom) => {
          return !newChatroom.map((c) => c.id).includes(chatroom.id);
        });
        chatrooms = [...pinned, ...newChatroom, ...newUnpinned];
      }
      return chatrooms;
    },
    sleep(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
    },
    messageSenderName(message) {
      return message.sender.nickname ?? message.sender.name;
    },
    async fetchChatroomsAtMin() {
      let response = await chatroomApi.getChatrooms(this.preparePayload());
      const newChatrooms = response.data.data
        .filter((chatroom) => {
          return !this.chatrooms.map((c) => c.id).includes(chatroom.id);
        })
        .map((chatroom) => {
          if (!chatroom.message) {
            chatroom.is_new_created = true;
          }
          return chatroom;
        });
      if (newChatrooms.length) {
        newChatrooms.forEach((c) => {
          this.chatrooms.push(c);
        });
      }
    },
    async removeMember(member) {
      this.$swal
        .fire({
          type: "warning",
          title: "從聊天室移除",
          text: "您確認要將此成員自聊天室移除嗎？",
          confirmButtonText: "確認",
          cancelButtonText: "取消",
          showCancelButton: true,
        })
        .then(async (result) => {
          if (result.value) {
            try {
              await chatroomApi.removeMember({
                chatroom: this.selectedChatroom,
                params: {
                  staff_id: member.id,
                }
              });
              this.$swal.fire("移除成員", "成功將成員自聊天室中移除", "success");
              this.fetchCustomers();
              this.fetchStaffs();
              this.fetchSingleChatroom();
            } catch (error) {
              console.error(error);
              let message = "失敗";
              if (error.response.data.message) {
                message = error.response.data.message;
              }
              this.$swal.fire("移除成員", message, "danger");
            }
          }
        });
    },
    downloadMultiImages() {
      let urls = this.selectedImageUrls.flat();
      if (urls.length === 0) {
        this.$swal.fire({
          type: "warning",
          title: "提醒",
          text: "您尚未選擇任何圖片"
        });
        return;
      }
      // 2022-12-12 先不確認，直接下載
      this.execMultiDownload(urls);
      // this.$swal
      //   .fire({
      //     type: "warning",
      //     title: "下載提醒",
      //     text: `請問是否下載已選取的 ${urls.length} 張圖片`,
      //     confirmButtonText: "確認",
      //     cancelButtonText: "取消",
      //     showCancelButton: true,
      //   })
      //   .then(async (result) => {
      //     if (result.value) {
      //       this.execMultiDownload();
      //     }
      //   });
    },
    async execMultiDownload(urls) {
      var temporaryDownloadLink = document.createElement("a");
      temporaryDownloadLink.style.display = 'none';
      document.body.appendChild( temporaryDownloadLink );
      for(var n = 0; n < urls.length; n++) {
          var download = urls[n];
          console.log(download);
          temporaryDownloadLink.setAttribute( 'href', download );
          if (this.targetBlank) {
            temporaryDownloadLink.setAttribute('target', '_blank');
          }
          temporaryDownloadLink.click();
          await this.sleep(500);
      }
      document.body.removeChild( temporaryDownloadLink );
    },
    cancelDownloadMultiImages() {
      this.selectedImageUrls = [];
    },
    async handleSelectedCategory(category) {
      this.regularFetchChatroomTrigger()
      this.changingFilter = true
      let value = category
      let els = this.selectedCategoryFilter;
      if (els.includes(value)) {
        els.splice(els.indexOf(value), 1);
      } else {
        els.push(value);
      }
      let response = await chatroomApi.getChatrooms(this.preparePayload())
      this.chatrooms = response.data.data;
      this.chatroomNextCursor = response.data.meta.nextCursor
      this.changingFilter = false
    },
    async handleSelectedStatus(status) {
      this.regularFetchChatroomTrigger()
      this.changingFilter = true
      let value = status
      let els = this.selectedStatus
      if (els.includes(value)) {
        els.splice(els.indexOf(value), 1);
      } else {
        els.push(value);
      }
      let response = await chatroomApi.getChatrooms(this.preparePayload())
      this.chatrooms = response.data.data
      this.chatroomNextCursor = response.data.meta.nextCursor
      this.changingFilter = false
    },
    regularFetchChatroomTrigger() {
      clearInterval(this.everyMinFetchChatroomTimer)
      this.everyMinFetchChatroomTimer = setInterval(()=> {this.fetchChatroomsAtMin()}, 60000);
    },
    preparePayload() {
      let payload = {}
      if (this.selectedBranch) {
        payload.branch_id = this.selectedBranch
      }
      if (this.allowFilterCategory && this.selectedCategoryValue) {
        payload.category = this.selectedCategoryValue
      }
      if (this.allowFilterStatus && this.selectedStatusValue) {
        payload.status = this.selectedStatusValue
      }
      if (this.keyword) {
        payload.keyword = this.keyword
      }
      return payload
    },
  },
  destroyed() {
    clearInterval(this.everyMinFetchChatroomTimer)
  },
};
</script>
<style lang="scss" scoped>
.chatroom {
  height: 80vh;
  .chat-list {
    min-width: 80px;
    max-width: 360px;
    width: 25%;
    overflow-x: hidden;
    .header {
      .search {
        position: relative;
        input {
          padding-left: 45px;
        }
        svg {
          position: absolute;
          left: 18px;
          top: 14px;
          height: 15px;
        }
      }
      .filter {
        i {
          color: gray;
        }
      }
    }
    .list {
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
  .resizer {
    background-color: #ccc;
    width: 0px;
    position: relative;
    i {
      position: absolute;
      top: 50%;
      padding: 5px;
      cursor: ew-resize;
    }
  }
  .chatbox {
    .message-container {
      overflow-y: auto;
      .message-form {
        font-size: 0;
      }
      .message-box {
        margin-right: auto;
        display: block;
        width: 100%;
        order: 2;
        .message-wrapper {
          max-width: 80%;
          display: inline-block;
          .message {
            background-color: #f4f6f9;
            border-radius: 1rem;
            border-bottom-left-radius: 0;
            max-width: 450px;
            a {
              color: inherit !important;
              text-decoration: underline;
              transition: opacity 0.15s ease-in-out;
              word-break: break-all;
              &:hover {
                opacity: 0.85;
              }
            }
            &.image {
              padding: 0 !important;
              border-radius: 0.5rem;
              overflow: hidden;
              cursor: pointer;
              img {
                width: auto;
                height: auto;
                max-width: 100%;
                max-height: none;
              }
              &.multiple {
                display: grid;
                grid-template-columns: repeat(2, minmax(0, 360px));
                gap: 0.5rem;
                background-color: transparent;
                div {
                  height: 0;
                  overflow: hidden;
                  padding-top: 100%;
                  position: relative;
                  &.expanded {
                    padding-top: 50%;
                    grid-column: span 2;
                  }
                }
                img {
                  position: absolute;
                  top: 0;
                  right: 0;
                  bottom: 0;
                  left: 0;
                  object-fit: cover;
                  width: 100%;
                  height: 100%;
                  max-width: 100%;
                  max-height: 100%;
                }
              }
            }
            &.file {
              border-radius: 1rem !important;
              p {
                margin: 0;
              }
              a {
                color: inherit;
                background: transparent;
                display: flex;
                align-items: center;
                text-decoration: none !important;
                .icon {
                  width: 3rem;
                  height: 3rem;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-right: 1rem;
                  background-color: rgba(255, 255, 255, 0.25);
                  border-radius: 50%;
                  flex-shrink: 0;
                  svg {
                    width: 2rem;
                    height: 2rem;
                    opacity: 0.75;
                  }
                }
              }
            }
          }
        }
        &.owner {
          align-self: flex-end;
          text-align: right;
          margin-left: auto;
          margin-right: 0;
          .message {
            background-color: #2196f3;
            border-radius: 1rem;
            border-bottom-right-radius: 0;
            color: white;
            text-align: left;
          }
        }
      }
    }
    .strike {
      display: flex;
      flex-direction: row;
      &:before,
      &:after {
        content: "";
        flex: 1 1;
        border-bottom: 1px solid #ccc;
        margin: auto 5px;
      }
    }
    .typebox {
      position: relative;
      .message-box {
        padding-right: 45px;
        height: calc(45px + 1.25em * (var(--lines) - 1));
        max-height: calc(45px + 1.25em * 9);
        line-height: 1.25;
        padding-top: calc((45px - 1.25em) / 2);
        padding-bottom: calc((45px - 1.25em) / 2);
        color: #000000;
        font-size: 1rem;
        display: flex;
        align-items: center;
      }
      .send {
        position: absolute;
        right: 0px;
        top: 50%;
        transform: translateY(-50%);
      }
      .unread {
        left: 0;
        right: 10px;
        top: -45px;
        text-align: center;
        opacity: 0.3;
      }
    }
  }
  .sidebox {
    min-width: 25%;
    width: 25%;
    overflow-y: scroll;
    .profile {
      div[aria-expanded="true"] {
        i::before {
          content: "\f077";
        }
      }
      div[aria-expanded="false"] {
        i::before {
          content: "\f078";
        }
      }
    }
  }
  .handler {
    cursor: ew-resize;
    width: 0;
  }
  .read-list {
    margin: 0;
    margin-right: 0.25rem;
    display: flex;
    justify-content: flex-end;
    li + li {
      margin-left: 0.125rem;
    }
  }
  .typing-list {
    order: 1;
  }
  .load-more {
    order: 3;
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 0.5rem;
  }
  .loading-spinner {
    order: 4;
  }
}
</style>
<style lang="scss">
.uploader {
  height: 300px;
  .custom-file-input {
    height: 100%;
  }
  .custom-file-label {
    height: 100%;
    width: 100%;
    &::after {
      content: none !important;
    }
    .dropzone {
      height: 300px;
    }
  }
}
.filter {
  .badge {
    font-size: 14px;
  }
}
.file-type-icon {
  width: 40px;
}
.tab-content {
  line-height: 1.3;
  height: 100%;
}
.tab-content > .active {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: calc(100% - 32px);
}
.last-message-wrapper {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width: 25rem;
}
.download-all {
  color: #999;
  cursor: pointer;
}
.image-download-section {
  display: flex;
  color: #999;
  font-size: 0.85em;
}
.custom-control-label {
  cursor: pointer;
}
</style>
Footer
© 2023 GitHub, Inc.
Footer navigation
Terms
Privacy
Security
Status
Docs
Contact GitHub
Pricing
API
Training
Blog
About
waltily-frontend/ChatroomIndex.vue at main · Waltily-Inc/waltily-frontend
