import filesize from 'filesize'
import Typing from '@/components/Page/Chatroom/Typing.vue'
import StickerPanel from '@/components/Page/Chatroom/StickerPanel.vue'
import ImageLightBox from '@/components/Page/Chatroom/ImageLightBox.vue'
import Upload from 'gcs-browser-upload'
import { encode } from '@/utils/Base64'

export default {
  components: { Typing, ImageLightBox, StickerPanel },
  data: () => ({
    senderLastReadAt: null,
    lastLatestMessageSentAt: null,
    typing: false,
    channel: null,
    typingSenders: [],
    timers: [],
    scrollTop: 0,
    firstLoad: true,
    stickerPackages: [],
    stickers: {},
    showStickerPanel: false,
  }),
  computed: {
    channelName() {
      return `chatrooms.${this.chatroomId}`
    },
    staffChannelName() {
      return `staff.${this.staffId}`
    },
    sendersById() {
      return this.senders.reduce((senders, sender) => {
        return { ...senders, [sender.id]: sender }
      }, {})
    },
    senderLastRead() {
      return this.senders.reduce((lastRead, sender) => {
        const readAt = new Date(sender.read_at)
        const messageId = this.messages.find((message) => {
          return new Date(message.created_at) <= readAt
        })?.id

        return { ...lastRead, [sender.id]: messageId }
      }, {})
    },
    latestMessage() {
      return this.messages[0] || {
        sender: {},
      }
    },
    messageLines() {
      if (!this.message) {
        return 1
      }

      return this.message.split('\n').length
    },
    sender() {
      return this.sendersById[this.senderId]
    },
    senderReadAt() {
      return this.sender?.read_at
    },
    firstUnreadMessage() {
      if (!this.senderLastReadAt) {
        return null
      }

      const senderLastReadAt = new Date(this.senderLastReadAt)

      const firstUnreadMessage = this.messages.findLast((message) => new Date(message.created_at) > senderLastReadAt)

      if (! firstUnreadMessage) {
        return null
      }

      if (this.firstNewMessage && this.firstNewMessage.id === firstUnreadMessage.id) {
        return null
      }

      return firstUnreadMessage
    },
    firstNewMessage() {
      if (!this.lastLatestMessageSentAt) {
        return null
      }

      const lastLatestMessageSentAt = new Date(this.lastLatestMessageSentAt)

      return this.messages.findLast((message) => new Date(message.created_at) > lastLatestMessageSentAt)
    },
    firstMessage() {
      if (!this.messages.length) {
        return null
      }

      if (this.nextCursor) {
        return null
      }

      return this.messages[this.messages.length - 1]
    }
  },
  watch: {
    message(value) {
      if (value && !this.typing) {
        return this.startTyping()
      }

      if (!value) {
        return this.endTyping()
      }
    },
  },
  beforeDestroy() {
    this.endTyping()
    this.leaveChannel()
    this.clearTimers()
  },
  methods: {
    listenForChannelEvents() {
      this.channel
        .listen('.chatroom.message.received', this.handleMessageReceived)
        .listen('.chatroom.message.recalled', this.handleMessageRecalled)
        .listen('.chatroom.sender.read', this.handleSenderRead)
        .listenForWhisper('typing.start', this.handleTypingStartEvent)
        .listenForWhisper('typing.end', this.handleTypingEndEvent)
    },
    listenForStaffChannelEvents() {
      this.echo.private(this.staffChannelName)
        .listen('.chatroom.created', this.handleChatroomCreated)
        .listen('.chatroom.message.received', this.handleMessageReceivedUpdateTab)
        .listen('.chatroom.message.recalled', this.handleMessageRecalled)
        .listen('.chatroom.sender.added', this.handleSenderAdded)
        .listen('.chatroom.sender.removed', this.handleSenderRemoved)
    },
    listenForBranchChannelEvents($branchId) {
      this.echo.private(`branch.${$branchId}`)
        .listen('.chatroom.created', this.handleChatroomCreated)
        .listen('.chatroom.message.recalled', this.handleMessageRecalled)
        .listen('.chatroom.message.received', this.handleMessageReceivedUpdateTab)
    },
    listenForOrgChannelEvents($orgId) {
      this.echo.private(`organization.${$orgId}`)
        .listen('.chatroom.created', this.handleChatroomCreated)
        .listen('.chatroom.message.recalled', this.handleMessageRecalled)
        .listen('.chatroom.message.received', this.handleMessageReceivedUpdateTab)
    },
    senderIsRead(sender, message) {
      return this.senderLastRead[sender.id] && message.id &&
             this.senderLastRead[sender.id] === message.id
    },
    handleSenderRead(event) {
      const sender = this.sendersById[event.sender.id]

      if (!sender) {
        return
      }

      sender.read_at = event.readAt
    },
    startTyping() {
      this.typing = true
      this.whisperTypingStartEvent()

      const timer = window.setTimeout(() => {
        this.typing = false
        this.timers.splice(this.timers.indexOf(timer), 1)
      }, 60000)

      this.timers.push(timer)
    },
    endTyping() {
      this.typing = false
      this.whisperTypingEndEvent()
    },
    whisperTypingStartEvent() {
      if (!this.channel) {
        return
      }

      this.channel.whisper('typing.start', { chatroomId: this.chatroomId, senderId: this.senderId })
    },
    whisperTypingEndEvent() {
      if (!this.channel) {
        return
      }

      this.channel.whisper('typing.end', { chatroomId: this.chatroomId, senderId: this.senderId })
    },
    handleTypingStartEvent({ chatroomId, senderId }) {
      if (chatroomId !== this.chatroomId) {
        return
      }

      this.addToTypingSenders(this.sendersById[senderId])
    },
    handleTypingEndEvent({ chatroomId, senderId }) {
      if (chatroomId !== this.chatroomId) {
        return
      }

      this.removeFromTypingSenders(this.sendersById[senderId])
    },
    addToTypingSenders(sender) {
      if (!sender) {
        return
      }

      if (this.findTypingSenderIndex(sender) !== -1) {
        return
      }

      this.typingSenders.push(sender)

      const timer = window.setTimeout(() => {
        this.removeFromTypingSenders(sender)
        this.timers.splice(this.timers.indexOf(timer), 1)
      }, 60000)

      this.timers.push(timer)
    },
    removeFromTypingSenders(sender) {
      const index = this.findTypingSenderIndex(sender)

      if (index === -1) {
        return
      }

      this.typingSenders.splice(index, 1)
    },
    clearTypingSenders() {
      this.typingSenders = []
    },
    senderHash(sender) {
      const { id, type } = sender

      return `${type}-${id}`
    },
    findTypingSenderIndex(needle) {
      return this.typingSenders.findIndex((sender) => {
        return this.senderHash(sender) === this.senderHash(needle)
      })
    },
    clearTimers() {
      this.timers.forEach(window.clearTimeout)
    },
    getFileinfo(file) {
      const name = file.name
      const mime = file.type
      const size = file.size

      return { name, mime, size }
    },
    filesizeForHuman (bytes) {
      return filesize(bytes)
    },
    showImageLightBox(srcs, index) {
      this.$refs.lightbox.show(srcs, index);
    },
    getLightBoxSrcs(message) {
      return message.meta.images.map((image) => image.originalContentUrl);
    },
    createUploader({ id, url, file }) {
      return new Upload({
        id,
        url,
        file,
        chunkSize: 10485760, // chunk size must be a multiple of 262144 (0.25mb)
      })
    },
    manipulateMessage(message) {
      message = this.htmlspecialchars(message)
      message = this.parseLink(message)
      message = this.nl2br(message)

      return message
    },
    htmlspecialchars(text) {
      const map = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#039;',
      }

      return text.replace(/[&<>"']/g, (char) => map[char])
    },
    parseLink(text) {
      return text.replace(
        /https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:(?:[-a-zA-Z0-9()@:%_+.~#?/=]|&amp;)*)/g,
        (url) => {
          let decoratedURL = url.replace(/&amp;/g, '&')

          if (typeof this.decorateURL === 'function') {
            decoratedURL = this.decorateURL(decoratedURL)
          }

          return `<a href="${decoratedURL}" target="_blank">${url}</a>`
        }
      )
    },
    nl2br(text) {
      return text.replace(/\r\n|\n\r|\n|\r/g, '<br>')
    },
    isPdfFile(link) {
      return link.match(/\.pdf$/)
    },
    pdfViewerLink({ fileUrl, fileName }) {
      const org = this.$route.params.orgCode
      const path = encode({ fileUrl, fileName })

      return `/${org}/liff/pdf/${path}`
    },
    setSenderLastReadAt() {
      this.senderLastReadAt = this.senderReadAt
    },
    setLastLatestMessageSentAt() {
      this.lastLatestMessageSentAt = this.messages[0]?.created_at
    },
    async fetchStickerPackages() {
      this.stickerPackages = await this.getStickerPackages()
    },
    async fetchStickers(packageId) {
      if (this.stickers[packageId]) {
        return
      }

      const stickers = await this.getStickers({ packageId })

      this.stickers = {
        ...this.stickers,
        [packageId]: stickers.reduce((stickers, sticker) => {
          return {
            ...stickers,
            [sticker.id]: sticker,
          }
        }, {}),
      }
    },
  },
}
